<template>
    <div class="center-container">
        <v-card class="center-card">
            <div class="d-flex justify-end pt-3 pl-3">
                <span class="text-center flex-grow-1" style="font-weight: bold; font-size: 30px; font-family: Times, serif;"
                    :style="{ color: $store.state.colorPrimary }">פרטי המשתמש
                </span>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on" :color="$store.state.colorPrimary" medium class="mr-2 me-3"
                            @click="openEditFrom()">
                            mdi-pencil-outline
                        </v-icon>
                    </template>
                    <span>עריכה</span>
                </v-tooltip>
            </div>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="6">

                            <v-card-title><span :style="{ color: $store.state.colorPrimary }">שם</span></v-card-title>
                            <v-card-text>{{ userDetails.name }}</v-card-text>
                        </v-col>
                        <v-col cols="6">
                            <v-card-title><span :style="{ color: $store.state.colorPrimary }">פלאפון</span></v-card-title>
                            <v-card-text>{{ userDetails.phone }}</v-card-text>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-card-title><span :style="{ color: $store.state.colorPrimary }">אימייל</span></v-card-title>
                            <v-card-text>{{ userDetails.email }}</v-card-text>
                        </v-col>
                        <v-col cols="6">
                            <v-card-title><span :style="{ color: $store.state.colorPrimary }">קבוצה</span></v-card-title>
                            <v-card-text>{{ userDetails.role }}</v-card-text>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-card-title><span :style="{ color: $store.state.colorPrimary }">הרשאות</span></v-card-title>
                            <v-card-text>{{ userDetails.permission }}</v-card-text>
                        </v-col>
                        <v-col cols="6">
                            <v-card-title><span :style="{ color: $store.state.colorPrimary }">APi
                                    Token</span></v-card-title>
                            <v-card-text>{{ userDetails.api_token }}</v-card-text>
                        </v-col>
                    </v-row>
                    <v-row v-if="progressShow">
                        <v-col class="d-flex justify-center">
                            <v-progress-circular indeterminate color="primary"></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
import ApiServices from '@/services/api.service'

export default {

    data() {
        return {
            progressShow: false,
            userDetails: {
                name: "aa",
                phone: "dd",
                email: "dd",
                role: "gg",
                permission: "gg",
                api_token: "gg",
            },
        }
    },
    methods: {

        async getUserDetails() {
            try {
                this.userDetails = {};
                this.progressShow = true;
                let token = localStorage.getItem("token");
                let api = process.env.VUE_APP_BASE_URL + "/user/get_user_details";
                const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
                this.progressShow = false;
                const jsonObject = await res.json();
                if (res.status >= 400) {
                    this.showSnackBar("שגיאה בקבלת נתונים", "red");
                } else if (res.status === 200) {
                    this.userDetails = jsonObject;
                }
            } catch (error) {
                this.progressShow = false;
                this.showSnackBar("Error get codes list: " + error, "red");
            }
        },
        openEditFrom() {
            this.$emit("Edit", this.userDetails);
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
    },
    mounted() {
        this.getUserDetails();
    },

};
</script>
<style>
.center-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    margin-right: 5px;
    margin-left: 5px;
}

.center-card {
    max-width: 900px;
    width: 100%;
    padding-bottom: 40px;
    margin-left: 5px;
    margin-right: 5px;
}
</style>
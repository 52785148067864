<template >
    <div>
        <div class="sideNav" v-if="isExpended"
        :style="{ width: $vuetify.breakpoint.mdAndUp ? '16%' : $vuetify.breakpoint.smAndUp ? '30%' : '80%' }">

            <div style=" padding: 7px; display: flex; justify-content: end;">
                <div id="manu_img" @click="expenedHandle()">
                    <img src="@/assets/icon_expend.svg">
                </div>
            </div>

            <div id="logo" style="text-align: center;"><a href="#">
                    <img src="https://cpanel.clickcall.co.il/serverscall/sys_icon/logo.png" width="350" height="75"> </a>
            </div>

            <div class="scroll-container">
                <div style="display: flex; justify-content: center; background-color:#0d2c6d !important;">
                    <v-expansion-panels accordion focusable flat multiple style="max-width: 100%; " color="#0d2c6d"
                        v-model="panel">
                        <v-expansion-panel style="border-bottom: 0.5px solid #cfcccc;">
                            <v-expansion-panel-header color="#0d2c6d" hide-actions @click="isActive = !isActive">
                                <div style="display: flex; justify-content: space-between;">
                                    <span style="color: white; font-weight: bold;">טבלאות</span>
                                    <v-icon color="white">
                                        {{ isActive ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                                    </v-icon>
                                </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content color="#0d2c6d">
                                <div style="padding-bottom: 40px;">
                                    <div v-if="permission === 'edit'" class="centered-div">
                                        <v-btn :color="'gradient-yellow-button-background'" small @click="createTable()">
                                            <span style="font-weight: 500; font-size: medium; color: black;">צור טבלה
                                                חדשה</span>
                                        </v-btn>
                                    </div>
                                </div>
                                <div v-for="item in tables" :key="item.name" style="margin-bottom: 20px;">
                                    <v-hover>
                                        <template v-slot:default="{ hover }">
                                            <v-card class="mx-2 card-style" @click="showTable(item)" 
                                                :color="selectedTable === item.name ? $store.state.colorSecondary : (hover ? $store.state.colorSecondary : 'gradient-grey-button-background')">
                                                <span style="font-weight: bold; font-size: larger; text-align: center;"
                                                    :style="{ color: $store.state.colorPrimary }">
                                                    {{ item.hebrew_name }}
                                                </span>
                                            </v-card>
                                        </template>
                                    </v-hover>
                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </div>
            </div>

        </div>

        <div v-else class="sideNavUnExpended" :style="{ width: $vuetify.breakpoint.smAndUp ? '3%' : '10%' }">
            <div style=" padding: 3px; display: flex; justify-content: center;">
                <div id="manu_img" @click="expenedHandle()">
                    <img src="@/assets/icon_expend.svg">
                </div>
            </div>
        </div>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </div>
</template>
      
<script>
import SnackBar from '@/components/widgets/snackBar.vue'
import Auth from '@/services/auth.service.js'
import ApiServices from '@/services/api.service.js'

export default {
    props: {
        newTable: Object,
    },
    components: {
        SnackBar
    },
    data: () => ({
        userName: "ffff",
        email: "grgrgegrg",
        permission: "",
        asSpecialPage: false,
        specialPage: "",
        tables: [],
        isExpended: true,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        dropdownOpen: false,
        isAdmin: false,
        isActive: true,
        panel: [0],
        isExpended: true,
        selectedTable: null
    }),
    methods: {
        async getTablesNames() {

            let api = process.env.VUE_APP_BASE_URL + "/user/get_tables_list"
            let token = localStorage.getItem("token");

            try {
                const response = await fetch(api, ApiServices.requestOptions("POST", "", token));
                if (response.status >= 400) {
                    this.showSnackBar("שגיאה בקבלת טבלאות", "red");
                } else if (response.status === 200) {
                    const jsonObject = await response.json();
                    if (!jsonObject) {
                        this.showSnackBar("אין טבלאות זמינות", "green");
                    }
                    this.tables = jsonObject;
                    this.sortByDateCreate();
                    this.$store.state.tables = this.tables
                }
            } catch (error) {
                this.showSnackBar("Error get tables: " + error, "red");
            }
        },
        sortByDateCreate() {
            this.tables.sort((a, b) => {
                const dateA = new Date(a.date_created);
                const dateB = new Date(b.date_created);
                return dateA - dateB;
            })
        },
        toggleDropdown() {
            this.dropdownOpen = !this.dropdownOpen;
        },
        showTable(table) {
            this.selectedTable = table.name;
            this.$emit("showTable", table);
        },
        createTable() {
            this.$emit("createTable");
        },
        getUserDetails() {
            this.userName = Auth.getUserName();
            this.email = Auth.getUserEmail();
            this.permission = Auth.getUserPermission();
        },
        logOut() {
            localStorage.removeItem("token");
            this.$router.replace({ name: "login" })
        },
        goToSpecial() {
            window.location.pathname = this.specialPage;
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
        expenedHandle() {
            console.log("childExtend");
            this.isExpended = !this.isExpended;
            this.$emit("isExtendedFunc")
        },
    },
    watch: {
        newTable() {
            this.tables.push(this.newTable);
        }
    },
    mounted() {
        if (localStorage.getItem("token2")) {
            this.isAdmin = true;
        }
        this.getTablesNames();
        this.getUserDetails();
    },
}
</script>
<style scoped>
.sideNav {
    position: fixed !important;
    top: 55px !important;
    right: 0 !important;
    bottom: 0 !important;
    width: 16% ;
    background: #0d2c6d !important;
    overflow: hidden;
    font-size: 1.55vh;
    z-index: 10 !important;
}

.sideNavUnExpended {
    position: fixed !important;
    top: 55px !important;
    right: 0 !important;
    width: 10%;
    height: 100vh;
    color: #fff;
    line-height: 1.4em;
    background: #0d2c6d;
    font-size: 1.55vh;
    overflow: hidden;
    z-index: 10;
    /* Ensure it overlays over #systembody */
}

.card-style {
    height: 50px !important;
    width: 100% !important;
    cursor: pointer;
}

.centered-div {
    flex: 1;
    display: flex;
    justify-content: center;
    padding-top: 10px;
}

#logo {
    margin-top: 6%;
    margin-bottom: 3%;
}

#logo img {
    width: 70%;
    height: auto;
}

.scroll-container {
    /* Ensure it takes at least the full viewport height */
    height: 85% !important;
    background: #0d2c6d !important;
    /* Make sure background is consistent */
    /* Take full height of its parent */
    overflow-y: auto;
    /* Enable vertical scrolling */
    overflow-x: hidden;
    /* Disable horizontal scrolling */
}


.gradient-yellow-button-background {
    background: linear-gradient(0deg, rgba(255, 185, 26, 1) 1%, rgba(255, 185, 26, 0.8127626050420168) 70%);
}

/* Customizes the scrollbar width */
::-webkit-scrollbar {
    width: 6px;
    /* Adjust the width of the scrollbar */
}

/* Customizes the scrollbar track (space to scroll) */
::-webkit-scrollbar-track {
    background-color: #FFFFFF;
    /* Sets the track color to white */
}

/* Customizes the scrollbar thumb (the draggable element) */
::-webkit-scrollbar-thumb {
    background-color: rgb(219, 218, 218);
    /* Sets the thumb color to blue */
    border-radius: 3px;
    /* Optional: Adds rounded corners to the thumb */
}</style>
      
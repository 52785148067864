<template >
  <v-container style="margin-top: 70px; margin-bottom: 70px;" v-if="operationSettings == false">
    <v-row class="d-flex justify-center">
      <v-col cols='12' lg='10'>
        <v-card elevation="4" style="padding-bottom: 50px;">
          <div class="d-flex justify-center pt-3">
            <v-btn  @click="backToTableData()" class="gradient-grey-button-background mx-2" fab dark small>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" :color="$store.state.colorPrimary">
                    mdi-arrow-right
                  </v-icon>
                </template>
                <span>חזור לטבלה</span>
              </v-tooltip>
            </v-btn>
            <span class="text-center flex-grow-1" style="font-weight: bold; font-size: 30px; font-family: Times, serif;"
              :style="{ color: $store.state.colorPrimary }">אינטיגרציה ל CRM - {{
                tableName.hebrew_name }}</span>
          </div>
          <div class="divider"></div>
          <v-card-title v-if="isTrigerChoosed">
            <v-row>
              <div  class="d-flex justify-center">
                <span style="font-weight: 700;">פעולות כאשר '{{ trigerChoosed.hebrew_name }}'</span>
              </div>
              <v-col cols="12" style="margin-bottom: 10px;">
                <span class="mx-2">פעולות: {{ operations.length }}</span>
                <v-btn  @click="createDialog = true" class="gradient-grey-button-background mx-2" fab dark small
                  style="max-width: 25px; max-height: 25px;">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" :color="$store.state.colorPrimary">
                        mdi-plus
                      </v-icon>
                    </template>
                    <span>הוסף פעולה</span>
                  </v-tooltip>
                </v-btn>
              </v-col>
              <v-row>
                <v-col cols="12" sm="6" md="4" v-for="(operation, index) in operations" :key="index">
                  <v-card class="mx-auto" max-width="344">
                    <v-card-text>
                      <p class="text-h6 text--primary text-center">
                        {{ operation.details.name }}
                      </p>
                      <div class="text--primary text-center">
                       מצב: <span style="font-weight: bold;">{{ operation.action.mode }}</span>  
                      </div>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn text :color="$store.state.colorPrimary" @click="openOperationSettings(operation, index)">
                        ערוך פעולה
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-row>
          </v-card-title>

          <v-card-title v-else>
            <v-row>
              <span>פעיל כאשר:</span>
              <v-col cols="12" sm="6" md="4" v-for="(triger, index) in trigers" :key="index">
                <v-hover>
                  <template v-slot:default="{ hover }">
                    <v-card class="mx-auto" max-width="344" @click="openOperationsList(triger)"
                      :elevation="hover ? 16 : 2">
                      <v-card-text>
                        <p class="text-h6 text--primary text-center" style="font-weight: bold;">
                          {{ triger.hebrew_name }}
                        </p>
                      </v-card-text>
                    </v-card>
                  </template>
                </v-hover>
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>

      </v-col>
    </v-row>

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>
    <CreateDialog v-model="createDialog" v-if="createDialog" @operationCreated="operationCreated"
      :tableName="tableName" :trigerName="trigerChoosed.name"/>
  </v-container>
  <OperationSettings v-model="operationSettings" v-else :operationToEdit="operationToEdit"
    @operationUpdated="operationUpdated" :tableName="tableName" />
</template>
    
<script>
import ApiServices from '@/services/api.service.js'
import SnackBar from '@/components/widgets/snackBar.vue'
import Auth from '@/services/auth.service.js'
import CreateDialog from '@/components/user/crmIntigration/CreateOperationDialog'
import OperationSettings from '@/components/user/crmIntigration/OperationSettings'

export default {
  components: {
    SnackBar,
    CreateDialog,
    OperationSettings
  },
  props: {
    tableName: Object,
    value: { type: Boolean, default: false },
  },
  data: () => ({
    operations: [],
    trigers: [],
    snackbar: false,
    snackbarColorBt: "green",
    snacbarText: "",
    createDialog: false,
    operationSettings: false,
    operationToEdit: {},
    trigerChoosed: "",
    isTrigerChoosed: false

  }),
  computed: {
    openMode: {
      get() {
        return this.value
      },
      set(v) {
        return this.$emit('input', v)
      }
    },
  },
  methods: {
    async getOperations() {
      try {
        this.operations = [];
        let token = localStorage.getItem("token");
        const tableJSON = JSON.stringify({ tableName: this.tableName.name, triger: this.trigerChoosed.name });

        let api = process.env.VUE_APP_BASE_URL + "/user/get_table_crm_operations";
        const res = await fetch(api, ApiServices.requestOptions("POST", tableJSON, token));
        const jsonObject = await res.json();
        if (res.status >= 400) {
          this.showSnackBar(" שגיאה בקבלת נתונים" + jsonObject.message, "red");
        } else if (res.status === 200) {
          this.operations = jsonObject.operations;
          this.trigers = jsonObject.trigers;
          if (this.trigerChoosed) {
            this.operations = this.operations.filter(operation => operation.details.triger === this.trigerChoosed.name)
          }

        }
      } catch (error) {
        this.showSnackBar("Error get operations list: " + error, "red");
      }
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
    operationCreated(item, text, color) {
      this.showSnackBar(text, color);
      this.getOperations()
    },
    openOperationSettings(operation, index) {
      this.operationToEdit = operation;
      this.operationSettings = true
    },
    openOperationsList(triger) {
      this.trigerChoosed = triger;
      this.operations = this.operations.filter(operation => operation.details.triger === this.trigerChoosed.name)
      this.isTrigerChoosed = true;
    },
    operationUpdated(operation, text, color) {
      this.showSnackBar(text, color);
      this.operationSettings = false;
      this.getOperations();
    },
    backToTableData() {
      if (this.isTrigerChoosed) {
        this.trigerChoosed = "";
        this.isTrigerChoosed = false;
        this.getOperations();
      } else {
        this.$emit('backToTableData', this.tableName)
      }
    }
  },
  mounted() {
    let auth = Auth.checkAuth();
    if (!auth) {
      this.$router.replace({ name: "login" })
    }
    this.getOperations();
  },
}
</script>
<style>
.divider {
  border-top: 0.5px solid #cfcccc;
  /* Set the border style, width, and color */
  margin-top: 10px;
  /* Optional: Add margin to adjust spacing */
  margin-bottom: 10px;
  /* Optional: Add margin to adjust spacing */
}

.user-info {
  display: flex;
  flex-direction: column;
}

.user-info span {
  margin-right: 2px;
}

.logout-button-container {
  display: flex;
  align-items: center;
}

.disable-margin {
  padding-bottom: 0 !important;
  padding-top: 12 !important;
  padding-left: 12 !important;
  padding-right: 12 !important;

}

.handle {
  cursor: move;
  margin-right: 10px;
}
</style>
    
<template>
  <div>
    <nav-bar></nav-bar>
    <SellerAccountEdit v-if="editChoose" :userDetails="details" @Edited="edited" @Cancle="editChoose = false" />
    <SellerAccount v-if="editChoose === false" @Edit="openEdit" />
    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

  </div>
</template>
<script>
import SellerAccount from '@/components/user/accountPage/UserAccount.vue'
import SellerAccountEdit from '@/components/user/accountPage/UserAccountEdit.vue'
import NavBar from '@/components/widgets/navBar.vue'
import SnackBar from '@/components/widgets/snackBar.vue'
import Auth from '@/services/auth.service.js'

export default {
  components: {
    SellerAccount,
    SellerAccountEdit,
    NavBar,
    SnackBar
  },
  data() {
    return {
      editChoose: false,
      snackbar: false,
      snackbarColorBt: "green",
      snacbarText: "",
      details: {},
    };
  },
  methods: {
    openEdit(details) {
      this.details = details;
      this.editChoose = true
    },
    edited(details) {
      this.editChoose = false
      this.showSnackBar("עודכן בהצלחה!", "green");
      this.details = details
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },

  },
  mounted() {
    document.title = 'My-Account | CLICKCALL'

    let auth = Auth.checkAuth();
      if (!auth) {
        this.$router.replace({ name: "login" })
      }
  },
}
</script>
  
<template>
    <div class="center-container">
        <v-card class="center-card">
            <div class="d-flex justify-end pt-3 pl-3">
                <span class="text-center flex-grow-1"
                    style="font-weight: bold; font-size: 30px; font-family: Times, serif;" :style="{color: $store.state.colorSecondary}">פרטי המשתמש
                </span>
            </div>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-card-title class="titleBold"><span :style="{color: $store.state.colorSecondary}">שם</span></v-card-title>
                            <v-card-text>{{ userDetails.name }}</v-card-text>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-card-title class="titleBold"><span :style="{color: $store.state.colorSecondary}">טלפון</span></v-card-title>
                            <v-card-text>{{ userDetails.phone }}</v-card-text>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-card-title class="titleBold"><span :style="{color: $store.state.colorSecondary}">אימייל</span></v-card-title>
                            <v-card-text>{{ userDetails.email }}</v-card-text>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-card-title class="titleBold"><span :style="{color: $store.state.colorSecondary}">קבוצה</span></v-card-title>
                            <v-card-text>{{ userDetails.role }}</v-card-text>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-card-title class="titleBold"><span :style="{color: $store.state.colorSecondary}">הרשאות</span></v-card-title>
                            <v-card-text>{{ userDetails.permission }}</v-card-text>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-card-title class="titleBold"><span :style="{color: $store.state.colorSecondary}">סטטוס</span></v-card-title>
                            <v-card-text>{{ userDetails.status }}</v-card-text>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-card-title class="titleBold"><span :style="{color: $store.state.colorSecondary}">Api token</span></v-card-title>
                            <v-card-text>{{ userDetails.api_token }}</v-card-text>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-card-title class="titleBold"><span :style="{color: $store.state.colorSecondary}">דף נוסף</span></v-card-title>
                            <v-card-text>{{ userDetails.special_page }}</v-card-text>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-card-title class="titleBold"><span :style="{color: $store.state.colorSecondary}">מסד נתונים</span></v-card-title>
                            <v-card-text>{{ userDetails.user_data_name }}</v-card-text>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-card-title class="titleBold"><span :style="{color: $store.state.colorSecondary}">פעילות אחרונה</span></v-card-title>
                            <v-card-text>{{ userDetails.last_visit }}</v-card-text>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-card-title class="titleBold"><span :style="{color: $store.state.colorSecondary}">נוצר בתאריך</span></v-card-title>
                            <v-card-text>{{ userDetails.created_at }}</v-card-text>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-card-title class="titleBold"><span :style="{color: $store.state.colorSecondary}">עודכן בתאריך</span></v-card-title>
                            <v-card-text>{{ userDetails.modified_at }}</v-card-text>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-card-title class="titleBold"><span :style="{color: $store.state.colorSecondary}">ID</span></v-card-title>
                            <v-card-text>{{ userDetails.id }}</v-card-text>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>

export default {

    data() {
        return {
            progressShow: false,
            userDetails: {
                name: "aa",
                nameInvoice: "bb",
                address: "cc",
                phone: "dd",
                email: "dd",
                role: "gg",
                permission: "gg",
                api_token: "gg",
            }
        }
    },
    methods: {
    },
    mounted() {
        this.userDetails = this.$route.params.user;
    },

};
</script>
<style>
.center-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    margin-right: 5px;
    margin-left: 5px;
}

.center-card {
    max-width: 900px;
    width: 100%;
    padding-bottom: 40px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 30px;
}

.titleBold{
   font-weight: bold;
}
</style>
<template>
    <v-dialog v-model="openMode" max-width="400px">
        <v-card class="gradient-grey-button-background">
            <v-card-title class="text-h5" :style="{ color: $store.state.colorPrimary }">{{ "ערוך רשימה" }}</v-card-title>
            <v-card-text>
                <div style="margin-top: 20px;">
                    <span class="mx-2" style="font-size: large;">נתונים: {{ fieldEditedList.list.length }}</span>
                    <v-btn  class="mx-2 gradient-grey-button-background" fab dark small @click="addItem()"
                        style="max-width: 25px; max-height: 25px;">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" :color="$store.state.colorPrimary">
                                    mdi-plus
                                </v-icon>
                            </template>
                            <span>הוסף שדה</span>
                        </v-tooltip>
                    </v-btn>
                </div>
                <div v-for="(item, index) in fieldEditedList.list">
                    <v-row>
                    <v-col cols="10">
                        <v-text-field v-model="fieldEditedList.list[index]" :label="'נתון ' + (index + 1)"
                        :rules="[v => !!v || 'שדה חובה']" required></v-text-field>
                    </v-col>
                    <v-col cols="2">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" :color="$store.state.colorPrimary" @click="deleteItem(index)">
                                    mdi-delete-outline
                                </v-icon>
                            </template>
                            <span>מחק נתון</span>
                        </v-tooltip>  
                    </v-col>
                </v-row>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :color="$store.state.colorPrimary" text @click="closeList()">ביטול</v-btn>
                <v-btn :color="$store.state.colorPrimary" text @click="actionConfirmed()">אישור</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>

    </v-dialog>
</template>
  
<script>
export default {
    props: {
        field: Object,
        fieldIndex: Number,
        value: { type: Boolean, default: false },
    },
    data: () => ({
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        fieldEditedList: {}
    }),

    methods: {
        actionConfirmed() {
            this.$emit("listUpdated", this.fieldEditedList, this.fieldIndex);
            this.openMode = false;
        },
        closeList() {
            this.$emit("listUpdated");
            this.openMode = false;
        },
        addItem() {
            this.fieldEditedList.list.push("");
        },
        deleteItem(index){
            this.fieldEditedList.list.splice(index, 1);
        }
    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
    },
    created() {
        //deep clone
        this.fieldEditedList = JSON.parse(JSON.stringify(this.field));
    },
}
</script>
<style></style>
    
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
    state:{
        fields:[],
        tables:[],
        colorPrimary: "#0d2c6d",
        colorSecondary: "#ffb91a",
        colorTertiary: "#E8EAF6",
        // colorBTPrimary: "linear-gradient(0deg, rgba(255, 185, 26, 1) 1%, rgba(255, 185, 26, 0.8127626050420168) 70%)",
    }
})
<template>
    <v-snackbar v-model="openMode" :timeout="4000" :multi-line="true"  bottom :color="color()">
        <span style="color: white;">{{ snacbarText }}</span>
        <template v-slot:action="{ attrs }">
            <v-btn color="white" text v-bind="attrs" @click="openMode = false">
                סגור
            </v-btn>
        </template>
    </v-snackbar>
</template>
<script>
export default {
    props: {
        snacbarText: String,
        snackbarColorBt: String,
        value: { type: Boolean, default: false },
    },
    methods: {
        color(){
           return this.snackbarColorBt === "red" ? "#d32f2f" : "green darken-3"
        }
    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
    },
}
</script>

  
<template >
  <v-container style="margin-top: 70px; margin-bottom: 70px;">
    <v-row class="d-flex justify-center">
      <v-col cols='auto'>
        <v-card elevation="4">
          <div class="d-flex justify-center pt-3">
            <v-btn :color="$store.state.colorPrimary" class="gradient-grey-button-background mx-2"
              @click="backToTableData()" fab dark small>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" :color="$store.state.colorPrimary">
                    mdi-arrow-right
                  </v-icon>
                </template>
                <span>חזור לטבלה</span>
              </v-tooltip>
            </v-btn>
            <span class="text-center flex-grow-1" style="font-weight: bold; font-size: 30px; font-family: Times, serif;"
              :style="{ color: $store.state.colorPrimary }">ערוך טבלה</span>
          </div>
          <div class="divider"></div>
          <v-card-title>
            <v-row>
              <v-col cols="auto" class="disable-margin" style="max-height: 50px;">
                <v-text-field v-model="tableName.hebrew_name" outlined dense label="שם הטבלה">
                </v-text-field>
              </v-col>
              <v-col cols="auto" class="disable-margin" style="max-height: 50px;"
                @click="copyToClipboard(tableName.name)">
                <v-text-field disabled v-model="tableName.name" outlined dense label="API table name">
                </v-text-field>
              </v-col>
              <v-col cols="auto">
                <v-btn class="gradient-grey-button-background" @click="exportJson()" fab dark small>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" :color="$store.state.colorPrimary">
                        mdi-share
                      </v-icon>
                    </template>
                    <span>ייצוא שדות לג'ייסון</span>
                  </v-tooltip>
                </v-btn>
              </v-col>
              <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
                <v-checkbox v-model="settings.multiple_selection" hide-details class="mx-2" :color="$store.state.colorPrimary"
                  label="אפשר מחיקה מרובה">
                </v-checkbox>
              </v-col>
              <v-col cols="12" style="padding-top: 0px;">
                <v-checkbox v-model="settings.expended_rows" hide-details class="mx-2" :color="$store.state.colorPrimary"
                  label="הרחב שורות">
                </v-checkbox>
              </v-col>
              <v-col cols="12">
                <span class="mx-2">שדות: {{ fields.length }}</span>
                <v-btn class="gradient-grey-button-background mx-2" @click="addFiled()" fab dark small
                  style="max-width: 25px; max-height: 25px;">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" :color="$store.state.colorPrimary">
                        mdi-plus
                      </v-icon>
                    </template>
                    <span>הוסף שדה</span>
                  </v-tooltip>
                </v-btn>
              </v-col>
              <draggable v-model="fields" :handle="'.handle'">
                <v-col cols="12" v-for="(field, index) in fields" :key="index" style="border: 1px solid #ccc;">
                  <v-row>
                    <v-col cols="auto" md="1">
                      <span class="handle" style="margin-left: 10px;">☰</span>
                      <span>{{ index + 1 }}.</span>
                    </v-col>
                    <v-col cols="auto" md="2" style="max-height: 60px;">
                      <v-text-field v-model="field.hebrew_name" outlined dense label="שם השדה"
                        v-if="field.name === 'date_created' || field.name === 'date_modified' || field.name === 'id'"
                        style="max-width: 500px; font-weight: bold;" disabled :rules="[v => !!v || 'שדה חובה']"
                        required></v-text-field>
                      <v-text-field v-else v-model="field.hebrew_name" outlined dense label="שם השדה"
                        style="max-width: 500px; font-weight: bold;" :rules="[v => !!v || 'שדה חובה']"
                        required></v-text-field>
                    </v-col>
                    <v-col @click="copyToClipboard(field.name)" cols="auto" md="2" style="max-height: 60px;">
                      <v-text-field disabled v-model="field.name" outlined dense label="API field name"
                        style="max-width: 500px; font-weight: bold;" required></v-text-field>
                    </v-col>
                    <v-col cols="auto" md="2" style="max-height: 60px; max-width: 250px">
                      <div class="d-flex align-center">
                        <v-select
                          v-if="field.name === 'date_created' || field.name === 'date_modified' || field.name === 'id'"
                          :items="fieldType" v-model="field.type" label="סוג שדה" outlined dense hide-details disabled
                          :rules="[v => !!v || 'שדה חובה']" required
                          :menu-props="{ bottom: true, offsetY: true }"></v-select>
                        <!-- if type is list add option to add list -->
                        <v-select v-else-if="field.type === 'רשימה'" :items="fieldType" v-model="field.type"
                          label="סוג שדה" outlined dense hide-details append-outer-icon="mdi-list-box-outline"
                          @click:append-outer="openListField(field, index)" :rules="[v => !!v || 'שדה חובה']" required
                          :menu-props="{ bottom: true, offsetY: true }"></v-select>
                        <!-- if type is list add option to add list -->
                        <v-select v-else-if="field.type === 'רשימה מקושרת'" :items="fieldType" v-model="field.type"
                          label="סוג שדה" outlined dense hide-details append-outer-icon="mdi-share"
                          @click:append-outer="openListLinkedField(field, index)" :rules="[v => !!v || 'שדה חובה']"
                          required :menu-props="{ bottom: true, offsetY: true }"></v-select>
                        <!-- if type is number add option to select coin -->
                        <v-menu v-model="isMenuOpen[index]" v-else-if="field.type === 'מספר'" offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-select :items="fieldType" v-model="field.type" label="סוג שדה" outlined dense hide-details
                              :rules="[v => !!v || 'שדה חובה']" required :append-outer-icon="getCurrencyIcon(field.coin)"
                              @click:append-outer="toggleMenu(index)"
                              :menu-props="{ bottom: true, offsetY: true }"></v-select>
                          </template>
                          <v-list>

                            <v-list-item v-for="(item, index) in coins" :key="index"
                              @click="field.coin = choosedCoin(item)" link>
                              <v-list-item-icon>
                                <v-icon>{{ getCurrencyIcon(item) }}</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title>{{ item }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                        <!-- if type is regular -->
                        <v-select v-else :items="fieldType" v-model="field.type" label="סוג שדה" outlined dense
                          hide-details :rules="[v => !!v || 'שדה חובה']" required
                          :menu-props="{ bottom: true, offsetY: true }">
                        </v-select>

                        <!-- <div v-if="field.type === 'רשימה'" style="border-radius: 4px; border: 1px solid #ccc; padding-top: 10px;">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" :color="$store.state.colorPrimary"
                                @click="openListField(field, index)">
                                mdi-list-box-outline
                              </v-icon>
                            </template>
                            <span>ערוך רשימה</span>
                          </v-tooltip>
                        </div> -->
                      </div>
                    </v-col>


                    <v-col cols="auto" md="2" style="max-height: 60px;">
                      <div class="d-flex align-center" style="border-radius: 4px; border: 1px solid #ccc; padding: 3px;">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" small style="margin-left: 2px; margin-right: 2px;">
                              mdi-help-circle
                            </v-icon>
                          </template>
                          <span>בעת הוספת שורה, ניתן יהיה להשאיר שדה זה ריק</span>
                        </v-tooltip>
                        <span class="ml-2">לאפשר ריק</span>
                        <v-checkbox
                          v-if="field.name === 'date_created' || field.name === 'date_modified' || field.name === 'id'"
                          disabled v-model="field.allowNull" dense hide-details></v-checkbox>
                        <v-checkbox v-else v-model="field.allowNull" dense hide-details
                          :color="$store.state.colorPrimary"></v-checkbox>
                      </div>
                    </v-col>
                    <v-col cols="auto" md="2" style="max-height: 60px;">
                      <div class="d-flex align-center" style="border-radius: 4px; border: 1px solid #ccc; padding: 3px;">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" small style="margin-left: 2px; margin-right: 2px;">
                              mdi-help-circle
                            </v-icon>
                          </template>
                          <span>שדה זה יכיל נתונים ייחודים, לא יהיה אפשרי להכניס נתון שכבר קיים בשורה אחרת</span>
                        </v-tooltip>
                        <span class="ml-2">ייחודי</span>
                        <v-checkbox
                          v-if="field.name === 'date_created' || field.name === 'date_modified' || field.name === 'id' || field.type === 'לינק'"
                          disabled :value="false" dense hide-details></v-checkbox>
                        <v-checkbox v-else v-model="field.unique" dense hide-details
                          :color="$store.state.colorPrimary"></v-checkbox>
                      </div>
                    </v-col>

                    <v-col cols="auto" md="1" style="max-height: 60px;">
                      <div class="d-flex align-center" style="border-radius: 4px; border: 1px solid #ccc; padding: 3px;">
                        <v-tooltip bottom class="ml-2" v-if="field.show">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon color="green" dark v-bind="attrs" v-on="on">
                              mdi-eye
                            </v-icon>
                          </template>
                          <span>אל תציג בטבלה</span>
                        </v-tooltip>
                        <v-tooltip bottom class="ml-2" v-else>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon color="red" dark v-bind="attrs" v-on="on">
                              mdi-eye-off
                            </v-icon>
                          </template>
                          <span>הצג בטבלה</span>
                        </v-tooltip>
                        <v-checkbox v-model="field.show" dense hide-details
                          :color="$store.state.colorPrimary"></v-checkbox>
                      </div>
                    </v-col>
                  </v-row>

                </v-col>
              </draggable>

              <v-col cols="12" class="d-flex justify-center">
                <v-btn @click="saveChanges()" v-if="allowSave" style="margin-bottom: 20px; margin-top: 30px;"
                  class="gradient-yellow-button-background mx-2">שמור שינויים</v-btn>
              </v-col>
              <v-col v-if="progressShow" class="d-flex justify-center">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </v-col>
            </v-row>

          </v-card-title>
        </v-card>
      </v-col>
    </v-row>

    <ListDialog v-model="listDialog" v-if="listDialog" :field="fieldEditList" :fieldIndex="fieldIndex"
      @listUpdated="listUpdated" />

    <ListLinkedDialog v-model="listLinkedDialog" v-if="listLinkedDialog" :field="fieldEditListLinked"
      :fieldIndex="fieldIndex" @listLinkedUpdated="listLinkedUpdated" />

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

  </v-container>
</template>
    
<script>
import ListDialog from '@/components/user/editTable/dialogs/ListDialog'
import ListLinkedDialog from '@/components/user/editTable/dialogs/ListLinkedDialog'
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'
import Auth from '@/services/auth.service.js'
import draggable from 'vuedraggable';

export default {
  components: {
    SnackBar,
    draggable,
    ListDialog,
    ListLinkedDialog
  },
  props: {
    tableName: Object,
    value: { type: Boolean, default: false },
  },
  data: () => ({
    fields: [],
    progressShow: true,
    snackbar: false,
    snackbarColorBt: "green",
    snacbarText: "",
    allowSave: true,
    fieldType: ["טקסט", "מספר", "תאריך", "תאריך ושעה", "רשימה", "רשימה מקושרת", "לינק"],
    listDialog: false,
    fieldEditList: {},
    fieldIndex: 0,
    fieldEditListLinked: {},
    listLinkedDialog: false,
    coins: ['לא נבחר', 'שקל', 'דולר', 'אירו'],
    isMenuOpen: [], // Initially, the menu is closed
    settings: { multiple_selection: true, expended_rows: false},
  }),
  computed: {
    openMode: {
      get() {
        return this.value
      },
      set(v) {
        return this.$emit('input', v)
      }
    },
  },
  methods: {
    toggleMenu(index) {
      this.$set(this.isMenuOpen, index, !this.isMenuOpen[index]); // Toggle the menu state using Vue's $set method
    },
    getCurrencyIcon(currency) {
      switch (currency) {
        case "שקל":
          return "mdi-currency-ils";
        case "דולר":
          return "mdi-currency-usd";
        case "אירו":
          return "mdi-currency-eur";
        case "₪":
          return "₪";
        case "$":
          return "$";
        case "€":
          return "€";
        default:
          return "mdi-hand-coin-outline";
      }
    },
    choosedCoin(item) {
      switch (item) {
        case "שקל":
          return "₪";
        case "דולר":
          return "$";
        case "אירו":
          return "€";
        default:
          return "";
      }
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text)
        .then(() => {
          this.showSnackBar("הועתק " + text, "green");
          // You can optionally show a success message to the user here
        })
        .catch((error) => {
          console.error('Error copying text to clipboard:', error);
          // You can optionally show an error message to the user here
        });
    },
    exportJson() {
      let jsonObject = {};
      for (const field of this.fields) {
        jsonObject[field.name] = '';
      }
      jsonObject = JSON.stringify(jsonObject, null, 2)
      navigator.clipboard.writeText(jsonObject)
        .then(() => {
          this.showSnackBar("הועתק " + "json", "green");
          // You can optionally show a success message to the user here
        })
        .catch((error) => {
          console.error('Error export json to clipboard:', error);
          // You can optionally show an error message to the user here
        });
    },
    async getData() {
      try {
        this.fields = [];
        this.progressShow = true;
        let token = localStorage.getItem("token");
        const tableJSON = JSON.stringify({ tableName: this.tableName.name });

        let api = process.env.VUE_APP_BASE_URL + "/user/get_table_properties";
        const res = await fetch(api, ApiServices.requestOptions("POST", tableJSON, token));
        const jsonObject = await res.json();
        this.progressShow = false;
        if (res.status >= 400) {
          this.showSnackBar(" שגיאה בקבלת נתונים" + jsonObject.message, "red");
          this.allowSave = false;
        } else if (res.status === 200) {
          this.fields = jsonObject.fieldsData;
          this.settings = jsonObject.settingsData;
          this.isMenuOpen = this.fields.map(() => false);
        }
      } catch (error) {
        this.progressShow = false;
        this.allowSave = false;
        this.showSnackBar("Error get items list: " + error, "red");
      }
    },
    isFieldNameValid(field) {
      return !field.includes('"');
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
    addFiled() {
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      let fieldName = '';
      for (let i = 0; i < 2; i++) {
        const randomIndex = Math.floor(Math.random() * chars.length);
        fieldName += chars.charAt(randomIndex);
      }
      fieldName += "_"
      this.fields.push({ name: fieldName, hebrew_name: "", allowNull: true, unique: false, show: true, type: "טקסט", list: [], linkedList: {}, coin: "" });
    },
    async saveChanges() {
      const fieldsJSON = JSON.stringify({ fields: this.fields, settings: this.settings, tableName: this.tableName });
      this.progressShow = true;
      let token = localStorage.getItem("token");
      try {
        let api = process.env.VUE_APP_BASE_URL + "/user/update_table_properties";
        const res = await fetch(api, ApiServices.requestOptions("PUT", fieldsJSON, token));
        this.progressShow = false;
        const jsonObject = await res.json();
        if (res.status >= 400) {
          console.log(res);
          this.showSnackBar(" שגיאה בעדכון שדות " + jsonObject.message, "red");
        } else if (res.status === 200) {
          this.showSnackBar("הפרטים נשמרו בהצלחה!", "green");
          setTimeout(() => {
            this.$emit('backToTableData', this.tableName)
          }, 800);
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error saved fields: " + error, "red");

      }
    },
    backToTableData() {
      this.$emit('backToTableData', this.tableName)
    },
    openListField(item, index) {
      this.fieldEditList = item
      this.fieldIndex = index;
      this.listDialog = true;
    },
    openListLinkedField(item, index) {
      this.fieldEditListLinked = item
      this.fieldIndex = index;
      this.listLinkedDialog = true;
    },
    listUpdated(fieldListUpdated, index) {
      this.fields[index] = fieldListUpdated;
    },
    listLinkedUpdated(fieldListLinkedUpdated, index) {
      this.fields[index] = fieldListLinkedUpdated;
    }
  },
  watch: {
    tableName() {
      this.getData();
    }
  },
  created() {
    let auth = Auth.checkAuth();
    if (!auth) {
      this.$router.replace({ name: "login" })
    }
    this.getData();
  },
}
</script>
<style>
.divider {
  border-top: 0.5px solid #cfcccc;
  /* Set the border style, width, and color */
  margin-top: 10px;
  /* Optional: Add margin to adjust spacing */
  margin-bottom: 10px;
  /* Optional: Add margin to adjust spacing */
}

.user-info {
  display: flex;
  flex-direction: column;
}

.user-info span {
  margin-right: 2px;
}

.logout-button-container {
  display: flex;
  align-items: center;
}

.disable-margin {
  padding-bottom: 0 !important;
  padding-top: 12 !important;
  padding-left: 12 !important;
  padding-right: 12 !important;

}

.handle {
  cursor: move;
  margin-right: 10px;
}
</style>
    
<template>
  <div class="g">
    <FormLogin/>
  </div>
</template>
<script>
import FormLogin from '@/components/auth/FormLogin.vue'
import Auth from '@/services/auth.service.js'

export default {
  components: {
    FormLogin
  },
  mounted() {
    let auth = Auth.checkAuth();
      if (auth) {
        this.$router.replace({ name: Auth.getUserRole()})
      } 
  },
}
</script>
<style>

.g {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #0d2c6d;
  align-items: center;
  justify-content: center;
  padding: 15px;
  box-sizing: border-box;
  transform: translateZ(0);
  animation: pages_colorIn__r4Ogf 2.5s ease-out;
}

@keyframes pages_colorIn__r4Ogf {
  0% {
    filter: grayscale(1);
  }

  100% {
    filter: grayscale(0);
  }
}
</style>
<template >
  <v-container style="margin-top: 70px; margin-bottom: 70px;">
    <v-row class="d-flex justify-center">
      <v-col cols='12' lg='12'>
        <v-data-table id="table1" :headers="headers" :items="users" :search="search" sort-by="index" class="elevation-4"
          :footer-props="{ 'items-per-page-options': [20, 50, 100, 500], 'items-per-page-text': 'שורות בעמוד:' }">

          <template v-slot:top>
            <v-card elevation="0">
              <v-card-title>
                <div class="d-flex flex-wrap pa-5 ">

                  <v-btn small outlined dark class="mb-2 ms-2 gradient-grey-button-background" style="max-width: 20px;" :style="{borderColor: $store.state.colorPrimary}">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" :color="$store.state.colorPrimary" dark @click="openCreateDIalog()">
                          mdi-plus
                        </v-icon>
                      </template>
                      <span>הוסף משתמש חדש</span>
                    </v-tooltip>
                  </v-btn>
                  <v-btn small  outlined dark class="mb-2 ms-2 gradient-grey-button-background" style="max-width: 20px;" :style="{borderColor: $store.state.colorPrimary}">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" :color="$store.state.colorPrimary" dark @click="downloadExcel()">
                          mdi-file-download-outline
                        </v-icon>
                      </template>
                      <span>הורד לקובץ אקסל</span>
                    </v-tooltip>
                  </v-btn>
                </div>
                <v-spacer></v-spacer>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="חיפוש" flat solo hide-details clearable class="text_fields_search">
                </v-text-field>
              </v-card-title>
            </v-card>
          </template>

          <template v-slot:item.index="{ item, index }">
            <tr>
              <td>{{ index + 1 }}</td>
            </tr>
          </template>

          <template v-slot:no-data>
            <v-row v-if="progressShow" class="my-5">
              <v-col class="d-flex justify-center">
                <v-progress-circular indeterminate :color="$store.state.colorPrimary"></v-progress-circular>
              </v-col>
            </v-row>
            <div class="ma-5">
              <span class="text-center" style="font-weight: bold; font-size: 25px; font-family: Times, serif;">אין משתמשים
              </span>
            </div>
            <v-btn :color="$store.state.colorPrimary" style="margin-bottom: 10px;" @click="refreshDetails()">
              Reset
            </v-btn>
          </template>

          <template v-slot:item.status="{ item }">
            <v-switch :color="$store.state.colorPrimary" v-model="item.status" @click="enableOrdisable(item.status, item.id)"
              :label="item.status == 1 ? 'פעיל' : 'מושבת'">
            </v-switch>
          </template>

          <template v-slot:item.actions="{ item, index }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" :color="$store.state.colorPrimary" medium class="me-3" @click="getFullDetails(item, index)">
                  mdi-account-details-outline
                </v-icon>
              </template>
              <span>פרטים נוספים</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" :color="$store.state.colorPrimary" medium class="mr-2 me-3" @click="openEditDialog(item, index)">
                  mdi-pencil-outline
                </v-icon>
              </template>
              <span>עריכה</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.role === 'user'">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" :color="$store.state.colorPrimary" medium class="me-3" @click="switchToUser(item, index)">
                  mdi-login-variant
                </v-icon>
              </template>
              <span>כניסה למערכת</span>
            </v-tooltip>
            <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" medium class="me-3" @click="openDeleteDialog(item, index)">
                  mdi-delete
                </v-icon>
              </template>
              <span>מחיקה</span>
            </v-tooltip> -->
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <AddEditDialog v-model="addEditDialog" v-if="addEditDialog" :formTitle="formTitle" :itemToEdit="editedItem"
      @userAdded="newUserSaved" @userEdited="editedUserSaved" :databases="databases"> </AddEditDialog>

    <Alert-dialog v-model="alertDialog" v-if="alertDialog" :formTitle="formTitle" :itemIndex="itemIndex"
      :item="itemToDelete" @userDeleted="userDeleted"> </Alert-dialog>

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

  </v-container>
</template>
    
<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'
import TableToExcel from "@linways/table-to-excel";
import AddEditDialog from '@/components/admin/dialogs/AddEditUserDialog'
import AlertDialog from '@/components/admin/dialogs/AlertDialog'
import Auth from '@/services/auth.service.js'
import utils from '@/util/utils'
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export default {
  components: {
    AddEditDialog,
    AlertDialog,
    SnackBar
  },
  data: () => ({
    headers: [
      { text: '#', value: "index", sortable: false },
      { text: 'שם', align: 'start', value: 'name', },
      { text: 'טלפון', value: 'phone' },
      { text: 'אימייל', value: 'email' },
      { text: 'קבוצה', value: 'role' },
      { text: 'הרשאות', value: 'permission' },
      { text: 'סטטוס', value: 'status' },
      { text: 'מסד נתונים', value: 'user_data_name' },
      { text: 'פעילות אחרונה', value: 'last_visit' },
      { text: 'פעולות', value: 'actions', sortable: false },

    ],
    alertDialog: false,
    addEditDialog: false,
    formTitle: "",
    users: [],
    databases: [],
    progressShow: false,
    snackbar: false,
    snackbarColorBt: "green",
    snacbarText: "",
    editedItem: {},
    itemIndex: 0,
    itemToDelete: {},
    userName: "",
    email: "",
    search: "",

  }),

  methods: {

    async getUsersDetails() {
      try {
        this.users = [];
        this.progressShow = true;
        let token = localStorage.getItem("token");

        let api = process.env.VUE_APP_BASE_URL + "/admin/get_users_list";
        const res = await fetch(api, ApiServices.requestOptions("POST", "", token));
        const jsonObject = await res.json();
        if (res.status >= 400) {
          this.showSnackBar("שגיאה בקבלת נתונים", "red");
          this.progressShow = false;
        } else if (res.status === 200) {
          this.users = jsonObject;
          this.dateFormatted();
          this.progressShow = false;
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error get dates list: " + error, "red");
        let d = utils.getCurrentDate()
      }
    },
    dateFormatted() {
      this.users = this.users.map((user) => {
        return {
          ...user,
          created_at: utils.getCurrentDate(user.created_at),
          modified_at: utils.getCurrentDate(user.modified_at)
        }
      });
    },
    async getDatabases() {
      try {
        this.databases = [];
        let token = localStorage.getItem("token");

        let api = process.env.VUE_APP_BASE_URL + "/admin/get_databases_list";
        const res = await fetch(api, ApiServices.requestOptions("POST", "", token));
        const jsonObject = await res.json();
        if (res.status >= 400) {
          this.showSnackBar("שגיאה בקבלת שמות מסדי הנתונים", "red");
        } else if (res.status === 200) {
          this.databases = jsonObject;
        }
      } catch (error) {
        this.showSnackBar("Error get databases names list: " + error, "red");
      }
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
    async enableOrdisable(statusChanged, id) {

      let message = statusChanged ? "הסוכן הופעל בהצלחה" : "הסוכן הושהה בהצלחה";
      let editUserstatus = JSON.stringify({ id: id, status: statusChanged })
      let token = localStorage.getItem("token");

      try {
        let api = process.env.VUE_APP_BASE_URL + "/admin/change_user_status";
        const res = await fetch(api, ApiServices.requestOptions("PUT", editUserstatus, token));
       if (res.status >= 400) {
          const jsonObject = await res.json();
          this.showSnackBar("שגיאה בשינוי סטטוס " + jsonObject.message, "red");
          this.openMode = false;
        } else if (res.status === 200) {
          this.showSnackBar(message, "green");
        }
      } catch (error) {
        this.showSnackBar("Error change user status: " + error, "red");
      }
    },

    downloadExcel() {

      const englishHeaders = [
        { text: 'id', align: 'start', value: 'code_verify', },
        { text: 'name', align: 'start', value: 'code_verify', },
        { text: 'phone', value: 'status' },
        { text: 'email', value: 'seller_owner' },
        { text: 'role', value: 'mobile_activated' },
        { text: 'permission', value: 'created_at' },
        { text: 'status', value: 'modified_at' },
        { text: 'user_data_name', value: 'user_data_name' },
        { text: 'last_visit', value: 'last_visit' },
        { text: 'status', value: 'modified_at' },
        { text: 'api_token', value: 'api_token' },
        { text: 'special_page', value: 'special_page' },
        { text: 'created_at', value: 'created_at' },
        { text: 'modified_at', value: 'modified_at' },

      ];

      const usersData = this.users.map(user => {
        // Create an object with all user data
        return {
          id: user.id,
          name: user.name,
          phone: user.phone,
          email: user.email,
          role: user.role,
          permission: user.permission,
          status: user.status,
          user_data_name: user.user_data_name,
          last_visit: user.last_visit,
          api_token: user.api_token,
          special_page: user.special_page,
          created_at: user.created_at,
          modified_at: user.modified_at,
        };
      });

      // Create a new workbook
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Sheet 1');

      // Add the headers to the worksheet
      const headerRow = worksheet.addRow(englishHeaders.map(header => header.text));

      // Set the style of the header row
      headerRow.font = { bold: true };

      // Add the user data to the worksheet
      usersData.forEach(user => {
        const dataRow = worksheet.addRow(Object.values(user));
      });

      // Auto-fit the column widths
      worksheet.columns.forEach(column => {
        column.width = 15;
      });

      // Generate a unique filename
      const timestamp = new Date().toISOString().replace(/:/g, '-');
      const filename = `usersList_${timestamp}.xlsx`;

      // Save the workbook as an Excel file
      workbook.xlsx.writeBuffer()
        .then(buffer => {
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          saveAs.saveAs(blob, filename);
        })
        .catch(error => {
          console.error('Error exporting data:', error);
        });

    },
    refreshPage() {
      location.reload();
    },
    openEditDialog(item, index) {
      this.formTitle = "ערוך משתמש"
      this.editedItem = item
      this.editedItem.itemIndex = index;
      this.addEditDialog = true
    },

    openDeleteDialog(item, index) {
      this.itemIndex = index;
      this.itemToDelete = item
      this.formTitle = "האם אתה בטוח שברצונך למחוק את המשתמש?"
      this.alertDialog = true
    },
    openCreateDIalog() {
      this.formTitle = "הוסף משתמש חדש"
      this.editedItem = {}
      this.addEditDialog = true
    },
    newUserSaved(item, text, color) {

      this.showSnackBar(text, color)
      if (item === "") {
        return
      }
      this.users.push(item);

    },
    editedUserSaved(item, text, color) {
      this.showSnackBar(text, color)
      if (item === "") {
        return
      }
      let g = Object.assign(this.users[item.itemIndex], item);

    },
    userDeleted(itemIndex, text, color) {
      if (itemIndex === "") {
        this.showSnackBar(text, color)
        return
      }
      this.users.splice(itemIndex, 1);
      this.showSnackBar(text, color)
    },
    getFullDetails(user, index) {
      this.$router.push({ name: "admin_details", params: { user: user } })
    },
    getUserDetails() {
      this.userName = Auth.getUserName();
      this.email = Auth.getUserEmail();
    },
    logOut() {
      localStorage.removeItem("token");
      localStorage.removeItem("token2");
      this.$router.replace({ name: "login" })
    },
    async switchToUser(item, index) {

      this.progressShow = true
      let token = localStorage.getItem("token");
      localStorage.setItem('token2', token);
      let MyJSON = JSON.stringify({ id: item.id });
      let api = process.env.VUE_APP_BASE_URL + "/admin/swich_to_user"

      try {
        const response = await fetch(api, ApiServices.requestOptions("POST", MyJSON, token));
        this.progressShow = false;
        const jsonObject = await response.json();
        if (response.status >= 400) {
          this.showSnackBar("שגיאת התחברות: " + jsonObject.message, "red");
        } else if (response.status === 200) {
          localStorage.setItem('token', jsonObject.token);
          this.$router.replace({ name: "user" })
        }
      } catch (error) {
        this.showSnackBar("Error Login: " + error, "red");
      }
    },
  },
  mounted() {
    this.getUserDetails();
    this.getUsersDetails();
    this.getDatabases();
  },
}
</script>
<style>
.divider {
  border-top: 0.5px solid #cfcccc;
  margin-top: 10px;
  margin-bottom: 10px;
}

.user-info {
  display: flex;
  flex-direction: column;
}

.user-info span {
  margin-right: 2px;
}

.logout-button-container {
  display: flex;
  align-items: center;
}
</style>
    
<template>
  <div>
    <nav-bar></nav-bar>
    <UsersList />
  </div>
</template>
<script>
import UsersList from '@/components/admin/UsersList'
import Auth from '../../services/auth.service'
import NavBar from '@/components/widgets/navBar.vue'

export default {
  components: {
    UsersList,
    NavBar
  },
  mounted() {
    let auth = Auth.checkAuth();
    let role = Auth.getUserRole();
    if (!auth || role !== "admin") {
      this.$router.replace({ name: "login" })
    }
  },
}
</script>
    
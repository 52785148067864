<template>
  <v-dialog v-model="openMode" max-width="500px">
    <v-card class="gradient-grey-button-background">
      <v-card-title class="text-h5">{{ formTitle }}</v-card-title>
      <v-row v-if="progressShow">
        <v-col class="d-flex justify-center">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :color="$store.state.colorPrimary" text @click="closeDelete">ביטול</v-btn>
        <v-btn :color="$store.state.colorPrimary" text @click="actionConfirmed">אישור</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

  </v-dialog>
</template>

<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'

export default {
  props: {
    item: {type: [Object, Array]}, // Accepts either an Object or an Array of Objects},
    tableName: Object,
    formTitle: String,
    value: { type: Boolean, default: false },

  },
  components: {
    SnackBar,
  },
  data: () => ({
    progressShow: false,
    snackbar: false,
    snackbarColorBt: "green",
    snacbarText: "",
  }),

  methods: {
    actionConfirmed() {
      this.deleteItem();
    },

    async deleteItem() {
      try {
        this.progressShow = true;
        let token = localStorage.getItem("token");
        const itemsJSON = JSON.stringify({item: this.item, tableName: this.tableName});
        let api = process.env.VUE_APP_BASE_URL + "/user/delete_item";
        if (Array.isArray(this.item)) {
          api = process.env.VUE_APP_BASE_URL + "/user/delete_items_selected";
        }
        const res = await fetch(api, ApiServices.requestOptions("DELETE", itemsJSON, token));
        if (res.status >= 400) {
          this.$emit("itemDeleted", "", "תקלה במחיקת רשומה", "red");
          this.progressShow = false;
        } else if (res.status === 200) {
          this.$emit("itemDeleted", this.item, "הרשומה נמחקה בהצלחה!", "green");
          this.progressShow = false;
          this.openMode = false;
        }
      } catch (error) {
        this.progressShow = false;
        this.$emit("itemDeleted", "", "תקלה במחיקת רשומה", "red");
      }
    },
    closeDelete() {
      this.openMode = false;
    }
  },
  computed: {
    openMode: {
      get() {
        return this.value
      },
      set(v) {
        return this.$emit('input', v)
      }
    },
  },
}
</script>
<style></style>
  
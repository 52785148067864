<template >
  <v-container style="margin-top: 70px; margin-bottom: 90px;">
    <v-row class="d-flex justify-center">
      <v-col cols='12' lg='10'>
        <v-card>
          <v-form @submit.prevent="submitHandler" ref="form">
            <div class="d-flex justify-center pt-3">
              <v-btn class="gradient-grey-button-background mx-2" @click="openMode = false" fab dark small>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" :color="$store.state.colorPrimary">
                      mdi-arrow-right
                    </v-icon>
                  </template>
                  <span>חזור לטבלה</span>
                </v-tooltip>
              </v-btn>
              <span class="text-center flex-grow-1" style="font-weight: bold; font-size: 30px; font-family: Times, serif;"
                :style="{ color: $store.state.colorPrimary }">הגדרות משימה</span>
            </div>
            <div class="divider"></div>
            <v-row>
              <v-col cols="11" sm="4" style="max-height: 60px; margin: 15px;">
                <v-text-field v-model="editedTask.name" outlined dense label="שם המשימה" maxlength="32"
                  style="font-weight: bold;" :rules="[v => !!v || 'שדה חובה']" required></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" style=" margin: 15px;" class="divider-col">
                <span style="font-weight: bold;">בחר פעולה</span>
                <v-row>
                  <v-col cols="auto" v-for="item in actions" :key="item.id">
                    <v-hover>
                      <template v-slot:default="{ hover }">
                        <a>
                          <v-card class="card-style" @click="chooseAction(item)" :elevation="hover ? 16 : 2" :dark="selectedItemAction === item.id ? true : (hover ? true : false)"
                            :color="selectedItemAction === item.id ? $store.state.colorPrimary : (hover ? $store.state.colorPrimary : 'white')">
                            <span style="font-weight: bold; font-size: larger; text-align: center;">
                              {{ item.hebrew_name }}
                            </span>
                          </v-card>
                        </a>
                      </template>
                    </v-hover>
                  </v-col>
                </v-row>
                <v-row style=" margin-top: 20px;">
                  <v-col cols="11" v-if="editedTask.action === 'update_field'">
                    <v-select :items="fieldsFiltered" v-model="editedTask.field" item-value="name" item-text="hebrew_name"
                      label="בחר תא לעדכון" outlined dense hide-details :rules="[v => !!v || 'שדה חובה']"
                      required></v-select>
                  </v-col>
                </v-row>
                <v-row style=" margin-top: 10px;">
                  <v-col cols="11" v-if="editedTask.action === 'update_field'">
                    <v-text-field v-model="editedTask.value" outlined dense label="ערך לעדכון"></v-text-field>
                  </v-col>
                </v-row>
                <v-row style=" margin-top: 20px;" v-if="editedTask.action === 'forward_row'">
                  <v-col cols="11">
                    <v-btn @click="copyTable()" style="margin-left: 20px;" :color="$store.state.colorPrimary" dark>שכפל
                      טבלה</v-btn>
                  </v-col>
                </v-row>
                <v-row style=" margin-top: 20px;" v-if="editedTask.action === 'forward_row'">
                  <v-col cols="11">
                    <v-select :items="tablesFiltered" v-model="editedTask.table_to_forward" item-value="name"
                      item-text="hebrew_name" label="בחר טבלה להעברה" outlined dense hide-details
                      :rules="[v => !!v || 'שדה חובה']" required></v-select>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" sm="5" style=" margin: 15px;">
                <v-row>
                  <v-col cols="11">
                    <v-select :items="trigers" v-model="editedTask.triger" item-value="name" item-text="hebrew_name"
                      label="גורם מפעיל" outlined dense hide-details :rules="[v => !!v || 'שדה חובה']"
                      required></v-select>
                  </v-col>
                  <v-col cols="11" v-if="editedTask.triger === 'after_days'">
                    <v-select :items="trigers[0].options" v-model="editedTask.option" label="בחר ימים" outlined dense
                      hide-details :rules="[v => !!v || 'שדה חובה']" required></v-select>
                  </v-col>
                  <v-col cols="11" v-if="editedTask.triger === 'after_hours'">
                    <v-select :items="trigers[1].options" v-model="editedTask.option" label="בחר שעות" outlined dense
                      hide-details :rules="[v => !!v || 'שדה חובה']" required></v-select>
                  </v-col>
                  <v-col cols="11" v-if="editedTask.triger === 'after_minutes'">
                    <v-select :items="trigers[2].options" v-model="editedTask.option" label="בחר דקות" outlined dense
                      hide-details :rules="[v => !!v || 'שדה חובה']" required></v-select>
                  </v-col>
                  <v-col cols="11" v-if="editedTask.triger === 'custome_date_field'">
                    <v-select :items="dateFieldsFiltered" v-model="editedTask.custom_field_date" item-value="name"
                      item-text="hebrew_name" label="בחר שדה לתאריך ביצוע" outlined dense hide-details
                      :rules="[v => !!v || 'שדה חובה']" required></v-select>
                  </v-col>
                </v-row>
              </v-col>


              <v-col cols="12" style="padding-bottom: 0 !important; margin-inline-start: 20px;">
                <v-switch :color="$store.state.colorPrimary" v-model="editedTask.mode" label="מופעל/מושבת">
                </v-switch>
              </v-col>
              <v-col cols="12" class="d-flex" style="margin: 20px;">
                <v-btn @click="saveChanges()"  style="margin-left: 20px;" class="gradient-yellow-button-background"
                  >שמור
                  שינויים</v-btn>
                <v-btn @click="deleteTask()"  style="margin-left: 20px;" class="gradient-yellow-button-background"
                  >מחק
                  משימה</v-btn>
              </v-col>
              <v-col cols="12" v-if="progressShow" class="d-flex justify-center">
                <v-progress-circular indeterminate :color="$store.state.colorPrimary"></v-progress-circular>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

  </v-container>
</template>
      
<script>
import ApiServices from '@/services/api.service.js'
import SnackBar from '@/components/widgets/snackBar.vue'
import Auth from '@/services/auth.service.js'

export default {
  components: {
    SnackBar,
  },
  props: {
    taskToEdit: Object,
    tableName: Object,
    trigers: Array,
    actions: Array,
    value: { type: Boolean, default: false },
  },
  data: () => ({
    tasks: [],
    fields: [],
    tables: [],
    progressShow: false,
    snackbar: false,
    snackbarColorBt: "green",
    snacbarText: "",
    createDialog: false,
    editedTask: {},
    selectedItemAction: null,
  }),
  computed: {
    openMode: {
      get() {
        return this.value
      },
      set(v) {
        return this.$emit('input', v)
      }
    },
    fieldsFiltered() {
      return this.fields.filter(field => field.name !== "date_created" && field.name !== "date_modified")
    },
    dateFieldsFiltered() {
      return this.fields.filter(field => field.name !== "date_created" && field.name !== "date_modified" && (field.type === "תאריך" || field.type === "תאריך ושעה"))
    },
    tablesFiltered() {
      return this.tables.filter(table => table.name !== this.tableName.name)
    },
  },
  methods: {
    async saveChanges() {
      if (this.$refs.form.validate()) {
        try {
          if (this.editedTask.mode === true) {
            this.editedTask.mode = "on"
          } else {
            this.editedTask.mode = "off"
          }

          let token = localStorage.getItem("token");
          const tableJSON = JSON.stringify(this.editedTask);

          let api = process.env.VUE_APP_BASE_URL + "/user/update_task";
          const res = await fetch(api, ApiServices.requestOptions("PUT", tableJSON, token));
          const jsonObject = await res.json();
          if (res.status >= 400) {
            this.showSnackBar("שגיאה בעדכון המשימה" + jsonObject.message, "red");
          } else if (res.status === 200) {
            this.$emit("taskUpdated", this.editedTask, "המשימה עודכנה בהצלחה!", "green");
          }
        } catch (error) {
          this.showSnackBar("Error update task: " + error, "red");
        }
      }
    },
    async deleteTask() {
      try {
        let token = localStorage.getItem("token");
        const tableJSON = JSON.stringify(this.editedTask);

        let api = process.env.VUE_APP_BASE_URL + "/user/delete_task";
        const res = await fetch(api, ApiServices.requestOptions("DELETE", tableJSON, token));
        const jsonObject = await res.json();
        if (res.status >= 400) {
          this.showSnackBar("שגיאה במחיקת המשימה" + jsonObject.message, "red");
        } else if (res.status === 200) {
          this.$emit("taskUpdated", this.editedTask, "המשימה נמחקה בהצלחה!", "green");
        }
      } catch (error) {
        this.showSnackBar("Error update task: " + error, "red");
      }
    },
    async copyTable() {
      try {
        let token = localStorage.getItem("token");
        const tableJSON = JSON.stringify({ tableName: this.tableName, fields: this.fields });

        let api = process.env.VUE_APP_BASE_URL + "/user/copy_table";
        const res = await fetch(api, ApiServices.requestOptions("POST", tableJSON, token));
        const jsonObject = await res.json();
        if (res.status >= 400) {
          this.showSnackBar("שגיאה בשכפול הטבלה" + jsonObject.message, "red");
        } else if (res.status === 200) {
          this.showSnackBar("הטבלה שוכפלה בהצלחה!", "green");
          this.tables.push({ name: jsonObject.name, hebrew_name: jsonObject.hebrew_name, date_created: jsonObject.date_created });
          this.$emit("tableCopied");
        }
      } catch (error) {
        this.showSnackBar("Error update task: " + error, "red");
      }

    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
    chooseAction(item) {
      this.selectedItemAction = item.id;
      this.editedTask.action = item.name
    }
  },
  mounted() {
    let auth = Auth.checkAuth();
    if (!auth) {
      this.$router.replace({ name: "login" })
    }
    this.editedTask = { ...this.taskToEdit };
    this.fields = this.$store.state.fields;
    this.tables = this.$store.state.tables;
    this.actions.forEach((actionItem) => {
      if (this.editedTask.action === actionItem.name) {
        this.selectedItemAction = actionItem.id;
      }
      if (this.editedTask.mode === "on" || this.editedTask.mode === true) {
        this.editedTask.mode = true
      } else {
        this.editedTask.mode = false
      }
    });
  },

}
</script>
<style>
.divider {
  border-top: 0.5px solid #cfcccc;
  /* Set the border style, width, and color */
  margin-top: 10px;
  /* Optional: Add margin to adjust spacing */
  margin-bottom: 10px;
  /* Optional: Add margin to adjust spacing */
}

.divider-col {
  border-left: 1px solid #ccc !important;
  /* Adjust the color and thickness as needed */
  padding-left: 10px;
  /* Add padding to create space between the border and content */
  margin-left: 10px;
  /* Adjust the margin to align the divider with the cards above */
}

.card-style {
  width: 200px !important;
  height: 70px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
</style>
      
<template>
  <div>
    <nav-bar></nav-bar>
    <TablesList @showTable="showTable" @createTable="createTable" @showDialer="showDialer()" :newTable="newTable"
      :key="tableListKey" @isExtendedFunc="isExtendedFunc()" />

    <div id="systembody" v-if="isExtended" 
      :style="{ width: $vuetify.breakpoint.mdAndUp ? '84%' : $vuetify.breakpoint.smAndUp ? '70%' : '90%', right: $vuetify.breakpoint.mdAndUp ? '16%' : $vuetify.breakpoint.smAndUp ? '30%' : '10%' }"> 
      <TableData v-model="tableData" v-if="tableData" :tableName="tableName" @openEditTable="openEditTable"
        @openTaskSchedule="openTaskSchedule" @openCrmIntigration="openCrmIntigration" @refreshTables="reloadTableList"
        @refreshData="reloadTableData" :key="tableDataKey" />
      <EditTable v-model="editTable" v-if="editTable" :tableName="tableName" @backToTableData="backToTableData" />
      <TasksList v-model="tasksList" v-if="tasksList" :tableName="tableName" @reloadTasksList="reloadTasksList"
        :key="tasksListKey" @backToTableData="backToTableData" @reloadTableList="reloadTableList" />
      <CrmIntigrationList v-model="crmIntigrationList" v-if="crmIntigrationList" :tableName="tableName"
        @reloadCrmIntigrationList="reloadCrmIntigrationList" :key="crmIntigrationListKey"
        @backToTableData="backToTableData" @reloadTableList="reloadTableList" />
    </div>
    <div id="systembody" v-else
      :style="{ width: $vuetify.breakpoint.smAndUp ? '97%' : '90%' , right: $vuetify.breakpoint.smAndUp ? '3%' : '10%'}"> 
      <TableData v-model="tableData" v-if="tableData" :tableName="tableName" @openEditTable="openEditTable"
        @openTaskSchedule="openTaskSchedule" @openCrmIntigration="openCrmIntigration" @refreshTables="reloadTableList"
        @refreshData="reloadTableData" :key="tableDataKey" />
      <EditTable v-model="editTable" v-if="editTable" :tableName="tableName" @backToTableData="backToTableData" />
      <TasksList v-model="tasksList" v-if="tasksList" :tableName="tableName" @reloadTasksList="reloadTasksList"
        :key="tasksListKey" @backToTableData="backToTableData" @reloadTableList="reloadTableList" />
      <CrmIntigrationList v-model="crmIntigrationList" v-if="crmIntigrationList" :tableName="tableName"
        @reloadCrmIntigrationList="reloadCrmIntigrationList" :key="crmIntigrationListKey"
        @backToTableData="backToTableData" @reloadTableList="reloadTableList" />
    </div>
    <CreateDialog v-model="createDialog" v-if="createDialog" @tableCreated="tableCreated" />
    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>
  </div>
</template>
<script>
import TablesList from '@/components/user/TablesList'
import TableData from '@/components/user/tableData/TableData'
import EditTable from '@/components/user/editTable/EditTable'
import TasksList from '@/components/user/taskSchedule/TasksList'
import CrmIntigrationList from '@/components/user/crmIntigration/CrmIntigrationList'
import CreateDialog from '@/components/user/editTable/dialogs/createDialog'
import Auth from '@/services/auth.service.js'
import SnackBar from '@/components/widgets/snackBar.vue'
import NavBar from '@/components/widgets/navBar.vue'

export default {
  name: 'home',
  components: {
    TablesList,
    TableData,
    EditTable,
    TasksList,
    CrmIntigrationList,
    CreateDialog,
    SnackBar,
    NavBar
  },
  data: () => ({
    tableData: false,
    tableName: {},
    editTable: false,
    tasksList: false,
    crmIntigrationList: false,
    createDialog: false,
    tableListKey: 0,
    tableDataKey: 0,
    tasksListKey: 0,
    crmIntigrationListKey: 0,
    dialer: false,
    newTable: null,
    snackbar: false,
    snackbarColorBt: "green",
    snacbarText: "",
    isExtended: true
  }),
  methods: {
    showTable(name) {
      if (!this.$route.params.tableName || this.$route.params.tableName !== name.name) {
        this.$router.replace({ name: "table-view", params: { tableName: name.name } });
      }
      this.tableName = name;
      this.editTable = false;
      this.tasksList = false;
      this.crmIntigrationList = false;
      // destroy previous table data (headers) before show new table
      this.reloadTableData();
      this.tableData = true;
    },
    createTable() {
      this.tableData = false;
      this.editTable = false;
      this.tasksList = false;
      this.crmIntigrationList = false;
      this.createDialog = true;
    },
    tableCreated(item, text, color) {
      if (item) {
        this.newTable = item;
      }
      this.showSnackBar(text, color);
    },
    openEditTable(table) {
      this.tableName = table;
      this.tableData = false;
      this.tasksList = false;
      this.crmIntigrationList = false;
      this.editTable = true;
    },
    openTaskSchedule(table) {
      this.tableName = table;
      this.tableData = false;
      this.editTable = false;
      this.crmIntigrationList = false;
      this.tasksList = true;
    },
    openCrmIntigration(table) {
      this.tableName = table;
      this.tableData = false;
      this.editTable = false;
      this.tasksList = false;
      this.crmIntigrationList = true;
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
    reloadTableList() {
      this.tableListKey += 1;  // Increment the key to trigger a reload
    },
    reloadTableData() {
      this.tableDataKey += 1;  // Increment the key to trigger a reload
    },
    reloadTasksList() {
      this.tasksListKey += 1;  // Increment the key to trigger a reload
    },
    reloadCrmIntigrationList() {
      this.crmIntigrationListKey += 1;  // Increment the key to trigger a reload
    },
    backToTableData(tableName) {
      this.tableName = tableName;
      this.editTable = false;
      this.tasksList = false;
      this.crmIntigrationList = false;
      this.tableData = true;
    },
    isExtendedFunc() {
      this.isExtended = !this.isExtended;
    }
  },
  mounted() {
    document.title = 'Dashboard | CLICK-CALL'
    let auth = Auth.checkAuth();
    if (!auth) {
      this.$router.replace({ name: "login" })
    }

    // direct access to table from url
    setTimeout(() => {
      if (this.$route.params.tableName) {
        for (let i = 0; i < this.$store.state.tables.length; i++) {
          if (this.$store.state.tables[i].name == this.$route.params.tableName) {
            this.showTable(this.$store.state.tables[i]);
          }
        }
      }
    }, 200);
  },
}
</script>
<style scoped>
div#systembody {
  position: absolute;
  top: 0;
  height: 100%;
  overflow: auto;
  min-height: 100%;
  background: #fbfbfb;
}

::-webkit-scrollbar {
  width: 10px;
  /* Adjust the width of the scrollbar */
}

/* Customizes the scrollbar track (space to scroll) */
::-webkit-scrollbar-track {
  background-color: #FFFFFF;
  /* Sets the track color to white */
}

/* Customizes the scrollbar thumb (the draggable element) */
::-webkit-scrollbar-thumb {
  background-color: rgb(219, 218, 218);
  /* Sets the thumb color to blue */
  border-radius: 3px;
  /* Optional: Adds rounded corners to the thumb */
}
</style>
<template>
  <div>
    <nav-bar></nav-bar>
    <div class="center-container">
      <v-card class="center-card">
        <div class="d-flex justify-end pt-3 pl-3">
          <span class="text-center flex-grow-1" style="font-weight: bold; font-size: 30px; font-family: Times, serif;"
            :style="{ color: $store.state.colorPrimary }">צור קשר
          </span>
        </div>
        <v-card-text>
          <v-form @submit.prevent="submitForm" ref="form">
            <v-text-field v-model="email" label="אימייל" required :rules="[v => !!v || 'שדה חובה']" style="margin-bottom: 10px;"></v-text-field>
            <v-textarea v-model="message" label="הודעה" rows="4" required :rules="[v => !!v || 'שדה חובה']" outlined dense></v-textarea>
            <v-col cols="12" class="d-flex justify-center">
            <v-btn type="submit" class="gradient-yellow-button-background">שלח</v-btn>
            </v-col>
          </v-form>
        </v-card-text>
        <v-row v-if="progressShow" class="my-5">
          <v-col class="d-flex justify-center">
            <v-progress-circular indeterminate :color="$store.state.colorPrimary"></v-progress-circular>
          </v-col>
        </v-row>
      </v-card>
    </div>

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

  </div>
</template>
<script>
import Auth from '@/services/auth.service.js'
import NavBar from '@/components/widgets/navBar.vue'
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'

export default {
  components: {
    NavBar,
    SnackBar
  },
  data() {
    return {
      email: '',
      message: '',
      progressShow: false,
      snackbar: false,
      snackbarColorBt: "green",
      snacbarText: "",
    };
  },
  methods: {
    async submitForm() {
      if (this.$refs.form.validate()) {

        let token = localStorage.getItem("token");

        this.progressShow = true
        let MyJSON = JSON.stringify({ emailInserted: this.email, message: this.message });
        let api = process.env.VUE_APP_BASE_URL + "/user/contact_us"

        try {
          const response = await fetch(api, ApiServices.requestOptions("POST", MyJSON, token));
          this.progressShow = false;
          const jsonObject = await response.json();
          if (response.status >= 400) {
            this.showSnackBar("שגיאת שליחה: " + jsonObject.message, "red");
          }  else if (response.status === 200) {
            this.showSnackBar("הבקשה נשלחה בהצלחה!", "green");
          }
        } catch (error) {
          this.showSnackBar("Error send message: " + error, "red");
        }

        // Clear form fields after submission
        this.email = '';
        this.message = '';
      }
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
  },
  mounted() {
    document.title = 'Contact-Us | CLICKCALL'

    let auth = Auth.checkAuth();
    if (!auth) {
      this.$router.replace({ name: "login" })
    }
  },
}
</script>
<style>
.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  margin-right: 5px;
  margin-left: 5px;
}

.center-card {
  max-width: 700px !important;
  width: 100% !important;
  padding-bottom: 40px !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
}
</style>
<template>
    <v-snackbar v-model="openMode" :timeout="50000" centered color="#e1e1e1a2">
        <div style="text-align: center;">
            <span style="color: rgba(85, 84, 84, 0.87); font-weight: bold;">טוען נתונים, נא להמתין...</span>
        </div>
    </v-snackbar>
</template>
<script>
export default {
    props: {
        value: { type: Boolean, default: false },
    },
    methods: {
    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
    },
}
</script>
<style>
.ff {
    color: #e1e1e1a2;
}
</style>
  
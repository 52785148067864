<template>
    <div>

        <v-card class="mx-auto" max-width="700" style="margin-top: 100px;">
            <div class="d-flex justify-end pt-3 pl-3">
                <span class="text-center flex-grow-1" :style="{ color: $store.state.colorPrimary }"
                    style="font-weight: bold; font-size: 30px; font-family: Times, serif;">
                    ערוך פרטי משתמש
                </span>
            </div>
            <v-card-text>
                <v-container>
                    <v-form @submit.prevent="submitHandler" ref="form">
                        <v-row>
                            <v-col cols="6">
                                <v-card-title :style="{ color: $store.state.colorPrimary }">שם</v-card-title>
                                <v-text-field v-model="userDetailsEdited.name" :rules="[v => !!v || 'שדה חובה']"
                                    required></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-card-title :style="{ color: $store.state.colorPrimary }">פלאפון</v-card-title>
                                <v-text-field v-model="userDetailsEdited.phone" :rules="[v => !!v || 'שדה חובה']"
                                    required></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                                <v-card-title :style="{ color: $store.state.colorPrimary }">אימייל</v-card-title>
                                <v-text-field v-model="userDetailsEdited.email" :rules="[v => !!v || 'שדה חובה']"
                                    required></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>

            <v-card-actions class="d-flex justify-center">
                <div style="margin-bottom: 30px;">
                    <v-btn class="gradient-yellow-button-background mx-2" text @click="cancelChanges">ביטול</v-btn>
                    <v-btn class="gradient-yellow-button-background mx-2" text @click="saveChanges">שמור</v-btn>
                </div>
            </v-card-actions>
            <v-row v-if="progressShow">
                <v-col class="d-flex justify-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </v-col>
            </v-row>
        </v-card>
        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </div>
</template>
  
<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'

export default {
    components: {
        SnackBar
    },
    props: {
        userDetails: Object
    },
    data() {
        return {
            progressShow: false,
            snackbar: false,
            snackbarColorBt: "green",
            snacbarText: "",
            userDetailsEdited: {
                name: "aa",
                phone: "dd",
                email: "dd",
            }

        };
    },
    methods: {
        async saveChanges() {

            if (this.$refs.form.validate()) {
                const editedJSON = JSON.stringify(this.userDetailsEdited);
                this.progressShow = true;
                let token = localStorage.getItem("token");

                try {
                    let api = process.env.VUE_APP_BASE_URL + "/user/update_user_details";
                    const res = await fetch(api, ApiServices.requestOptions("PUT", editedJSON, token));
                    this.progressShow = false;
                    const jsonObject = await res.json();
                    if (res.status >= 400) {
                        this.showSnackBar(jsonObject.message, "red");
                    }
                    if (res.status === 200) {
                        this.$emit("Edited", this.userDetailsEdited);
                    }
                } catch (error) {
                    this.progressShow = false;
                    this.showSnackBar("Error saved details: " + error, "red");
                }
            }
        },
        cancelChanges() {
            this.$emit("Cancle");
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
    },
    mounted() {
        this.userDetailsEdited = this.userDetails
    },
};
</script>
  
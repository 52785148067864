<template>
  <v-dialog v-model="openMode" max-width="500px">
    <v-card class="gradient-grey-button-background">
      <v-card-title class="text-h5">בחר קובץ להורדה</v-card-title>
      <v-card-text class="d-flex justify-space-around">
        <v-card style="margin-top: 20px; margin-bottom: 20px;">
          <a href="#" class="content-card" @click="downloadDataToExcel()" style="text-decoration: none;">
            <v-card-text class="content-text">הורד טבלה לאקסל</v-card-text>
          </a>
        </v-card>
        <v-card style="margin-top: 20px; margin-bottom: 20px;">
          <a href="#" class="content-card" @click="downloadExapmleExcel()" style="text-decoration: none;">
            <v-card-text class="content-text decoration-none" :color="$store.state.colorPrimary">הורד דוגמא לצורך
              ייבוא</v-card-text>
          </a>
        </v-card>
      </v-card-text>
      <v-row v-if="progressShow">
        <v-col class="d-flex justify-center">
          <v-progress-circular indeterminate :color="$store.state.colorPrimary"></v-progress-circular>
        </v-col>
      </v-row>
    </v-card>

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

  </v-dialog>
</template>
  
<script>
import SnackBar from '@/components/widgets/snackBar.vue'

export default {
  props: {
    tableData: HTMLDivElement,
    value: { type: Boolean, default: false },

  },
  components: {
    SnackBar,
  },
  data: () => ({
    progressShow: false,
    snackbar: false,
    snackbarColorBt: "green",
    snacbarText: "",
  }),

  methods: {
    downloadDataToExcel() {
      this.progressShow = true
      this.$emit("exportDataToExcel");
      setTimeout(async () => {
        this.progressShow = false
      }, 700);
    },
    async downloadExapmleExcel() {
      this.progressShow = true
      this.$emit("exportExampleExcel");
      this.progressShow = false
    },
  },
  computed: {
    openMode: {
      get() {
        return this.value
      },
      set(v) {
        return this.$emit('input', v)
      }
    },
  },
}
</script>
<style></style>
    
<template>
  <v-dialog v-model="openMode" max-width="500px">
    <v-card class="gradient-grey-button-background">
      <v-card-title class="text-h5">{{ formTitle }}</v-card-title>
      <v-row v-if="progressShow">
        <v-col class="d-flex justify-center">
          <v-progress-circular indeterminate :color="$store.state.colorPrimary"></v-progress-circular>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :color="$store.state.colorPrimary" text @click="closeDelete">ביטול</v-btn>
        <v-btn :color="$store.state.colorPrimary" text @click="actionConfirmed">אישור</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ApiServices from '@/services/api.service'

export default {
  props: {
    itemIndex: Number,
    item: Object,
    formTitle: String,
    value: { type: Boolean, default: false },

  },
  data: () => ({
    progressShow: false,
  }),

  methods: {
    actionConfirmed() {
      this.deleteUser();
    },

    async deleteUser() {
      try {
        this.progressShow = true;
        let token = localStorage.getItem("token");
        const datesJSON = JSON.stringify(this.item);

        let api = process.env.VUE_APP_BASE_URL + "/admin/delete_user";
        const res = await fetch(api, ApiServices.requestOptions("DELETE", datesJSON, token));
        this.progressShow = false;
        const jsonObject = await res.json();
        if (res.status >= 400) {
          this.$emit("userDeleted", "", "שגיאה במחיקת משתמש" + jsonObject.message, "red");
        } else if (res.status === 200) {
          this.$emit("userDeleted", this.itemIndex, "המשתמש נמחק בהצלחה!", "green");
          this.openMode = false;
        }
      } catch (error) {
        this.progressShow = false;
        this.$emit("userDeleted", "", "שגיאה במחיקת משתמש: " + error, "red");
      }
    },
    closeDelete() {
      this.openMode = false;
    }
  },
  computed: {
    openMode: {
      get() {
        return this.value
      },
      set(v) {
        return this.$emit('input', v)
      }
    },
  },
}
</script>

  
<template>
    <v-dialog v-model="openMode" max-width="350px">
        <v-card class="gradient-grey-button-background">
            <v-card-title class="text-h5">{{ "צור טבלה" }}</v-card-title>
            <v-card-text>
                <v-text-field v-model="tableName" label="שם הטבלה" :rules="[v => !!v || 'שדה חובה']" required>
                </v-text-field>
            </v-card-text>

            <v-row v-if="progressShow">
                <v-col class="d-flex justify-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </v-col>
            </v-row>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeCreate">ביטול</v-btn>
                <v-btn color="blue darken-1" text @click="actionConfirmed">אישור</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </v-dialog>
</template>
  
<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'

export default {
    props: {
        value: { type: Boolean, default: false },
    },
    components: {
        SnackBar,
    },
    data: () => ({
        tableName: "",
        progressShow: false,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
    }),

    methods: {
        actionConfirmed() {
            this.CreateTable();
        },

        async CreateTable() {
          
            try {
                this.progressShow = true;
                let token = localStorage.getItem("token");
                const itemsJSON = JSON.stringify({ tableName: this.tableName });

                let api = process.env.VUE_APP_BASE_URL + "/user/create_table";
                const res = await fetch(api, ApiServices.requestOptions("POST", itemsJSON, token));
                this.progressShow = false;
                const jsonObject = await res.json();
                if (res.status >= 400) {
                    this.$emit("tableCreated", "", "תקלה ביצירת טבלה " + jsonObject.message, "red");
                } else if (res.status === 200) {
                    this.$emit("tableCreated", jsonObject, "הטבלה נוצרה בהצלחה!", "green");
                    this.openMode = false;
                }
            } catch (error) {
                this.progressShow = false;
                this.$emit("tableCreated", "", "תקלה ביצירת טבלה", "red");
            }
        },
        closeCreate() {
            this.openMode = false;
        }
    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
        isTableNameValid() {
            return !this.tableName.includes('"');
        },
    },
}
</script>
<style></style>
    
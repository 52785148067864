<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>
<script>
import Auth from '@/services/auth.service.js'

export default {
  mounted() {
    setInterval(() => {
      let auth = Auth.checkAuth();
      if (!auth) {
        this.$router.replace({ name: "login" })
      }
    }, 2 * 60 * 1000);
  },

}
</script>
<style >

.text_fields_search {
    border: 1px solid #eee;
    border-radius: 13px;
    margin-left: 20px;
    padding-left: 10px;
    padding-right: 10px;
}

.gradient-yellow-button-background {
    background: linear-gradient(0deg, rgba(255, 185, 26, 1) 1%, rgba(255, 185, 26, 0.8127626050420168) 70%);
}

.gradient-grey-button-background {
    background: linear-gradient(0deg, rgb(219, 218, 218) 1%, rgba(255, 255, 255, 1) 70%);
}

#table1.v-data-table .v-data-table-header th {
  background-color: #e1e1e1 !important;
    text-align: center !important;
    border-left: 1px solid white !important;
    font-family: 'Heebo', sans-serif;
}

/* Style for single-line cells with overflow */
.single-line-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 120px;
}
/* Customizes the scrollbar width */
::-webkit-scrollbar {
  width: 0px; /* Adjust the width of the scrollbar */
}

/* Customizes the scrollbar track (space to scroll) */
::-webkit-scrollbar-track {
  background-color: #FFFFFF; /* Sets the track color to white */
}

/* Customizes the scrollbar thumb (the draggable element) */
::-webkit-scrollbar-thumb {
  background-color: rgb(219, 218, 218); /* Sets the thumb color to blue */
  border-radius: 3px; /* Optional: Adds rounded corners to the thumb */
}

</style>

<template >
  <v-container style="margin-top: 40px;">
    <v-row class="d-flex justify-center">
      <v-col cols='12'>
        <v-card elevation="0">
          <v-row v-if="permission === 'edit'" class="d-flex align-items-center pt-3">
            <v-col cols='12' sm="6" md="5">
              <v-btn outlined class="mx-2 gradient-yellow-button-background" fab dark small @click="openEditTablePage()">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" medium :color="$store.state.colorPrimary">
                      mdi-table
                    </v-icon>
                  </template>
                  <span>מאפייני טבלה</span>
                </v-tooltip>
              </v-btn>
              <v-btn outlined class="mx-2 gradient-yellow-button-background" fab dark small
                @click="openTaskSchedulePage()">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" :color="$store.state.colorPrimary">
                      mdi-calendar-clock-outline
                    </v-icon>
                  </template>
                  <span>תזמון משימות</span>
                </v-tooltip>
              </v-btn>
              <v-btn outlined class="mx-2 gradient-yellow-button-background" fab dark small
                @click="openCrmIntigrationPage()">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" :color="$store.state.colorPrimary">
                      mdi-puzzle-outline
                    </v-icon>
                  </template>
                  <span>אינטיגרציה ל CRM</span>
                </v-tooltip>
              </v-btn>
              <v-btn outlined class="mx-2 gradient-yellow-button-background" fab dark small>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" :color="$store.state.colorPrimary" dark @click="deleteTableDialog = true">
                      mdi-delete-outline
                    </v-icon>
                  </template>
                  <span>מחק טבלה</span>
                </v-tooltip>
              </v-btn>
            </v-col>
            <v-col cols='12' sm="6" md="7">
              <span class="text-center flex-grow-1" style="font-weight: bold; font-size: 30px; font-family: Times, serif;"
                :style="{ color: $store.state.colorPrimary }">{{
                  tableName.hebrew_name }}</span>
            </v-col>

          </v-row>
          <div v-else class="d-flex justify-center align-items-center pt-3">
            <span class="text-center flex-grow-1" style="font-weight: bold; font-size: 30px; font-family: Times, serif;"
              :style="{ color: $store.state.colorSecondary }">{{
                tableName.hebrew_name }}</span>
          </div>
          <div class="divider"></div>

          <v-card elevation="0" style="margin-bottom: 10px;">
            <v-card-title class="topTable">
              <div class="d-flex flex-wrap pa-5" style="padding-bottom: 0 !important; padding-top: 0 !important;">
                <v-btn v-if="FieldsNotEmpty && permission !== 'view'" outlined small color="indigo darken-4" dark
                  style="max-width: 20px;" class="mb-2 ms-2 gradient-grey-button-background" @click="openCreateDIalog()">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" :color="$store.state.colorPrimary" dark>
                        mdi-plus
                      </v-icon>
                    </template>
                    <span>צור רשומה חדשה</span>
                  </v-tooltip>
                </v-btn>
                <v-btn v-if="items.length > 0" outlined small color="indigo darken-4" dark style="max-width: 20px;"
                  class="mb-2 ms-2 gradient-grey-button-background" @click="openExportDialog()">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" :color="$store.state.colorPrimary" dark>
                        mdi-file-download-outline
                      </v-icon>
                    </template>
                    <span>ייצוא לקובץ אקסל</span>
                  </v-tooltip>
                </v-btn>
                <v-btn v-if="items.length > 0 && permission !== 'view'" outlined small color="indigo darken-4" dark
                  class="mb-2 ms-2 gradient-grey-button-background" style="max-width: 20px;"
                  @click="$refs.fileInput.click()">
                  <input type="file" style="display: none;" accept=".xls, .xlsx" ref="fileInput"
                    @change="handleFileUpload">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" :color="$store.state.colorPrimary" dark>
                        mdi-file-upload-outline
                      </v-icon>
                    </template>
                    <span>ייבוא מאקסל</span>
                  </v-tooltip>
                </v-btn>
                <v-btn outlined small color="indigo darken-4" dark class="mb-2 ms-2 gradient-grey-button-background"
                  style="max-width: 20px;" @click="refreshDetails()">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" :color="$store.state.colorPrimary" dark>
                        mdi-refresh
                      </v-icon>
                    </template>
                    <span>רענן תוצאות</span>
                  </v-tooltip>
                </v-btn>
                <v-btn outlined small color="indigo darken-4" dark class="mb-2 ms-2 gradient-grey-button-background"
                  style="max-width: 20px;" @click="extendTable()">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" :color="$store.state.colorPrimary" dark>
                        {{ settings.expended_rows ? 'mdi-arrow-collapse-horizontal' : 'mdi-arrow-expand-horizontal' }}
                      </v-icon>
                    </template>
                    <span>{{ settings.expended_rows ? 'צמצם שורות' : 'הרחב שורות' }}</span>
                  </v-tooltip>
                </v-btn>
                <v-btn v-if="selected.length > 0" outlined small color="indigo darken-4" dark
                  class="mb-2 ms-2 gradient-grey-button-background" style="max-width: 20px;"
                  @click="openDeleteDialog(selected)">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" :color="$store.state.colorPrimary" dark>
                        mdi-delete
                      </v-icon>
                    </template>
                    <span>מחק בחירה</span>
                  </v-tooltip>
                </v-btn>
              </div>
              <v-spacer></v-spacer>
              <span style="color: #bbbaba; font-weight: 600;">סה"כ רשומות: {{ totalItems }}</span>
              <v-spacer></v-spacer>
              <v-text-field v-model="search" @keydown.enter="handleEnterKey" append-icon="mdi-magnify" label="חיפוש"
                class="text_fields_search" flat solo hide-details clearable>
              </v-text-field>
            </v-card-title>
          </v-card>
        </v-card>

        <v-data-table id="table1" :headers="visibleHeaders" :items="items" item-key="id" :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc" @update:sort-by="onSortChange" @update:sort-desc="onSortChange"
          :items-per-page="itemsPerPage" @update:items-per-page="onItemsPerPageChange" :page.sync="page"
          :server-items-length="totalItems" @update:page="onPageChange" color="red"
          style="overflow-y: auto; max-height: 84vh;" class="elevation-0" :single-select="singleSelect" :show-select="settings.multiple_selection && permission !== 'view'"
          v-model="selected" :header-props="{ style: 'background-color: #ff0000' }"
          :footer-props="{ 'items-per-page-options': [20, 50, 100, 500], 'items-per-page-text': 'שורות בעמוד:' }">

          <!-- add coin icon to headers -->
          <template v-for="header in headers" v-slot:[`header.${header.value}`]="{ header }">
            <span style="color: #0d2c6d;" :style="settings.expended_rows ? 'font-size: 16px' : ''">{{ header.text }}</span>
            <span v-if="header.coin" style="color: #0d2c6d;" :style="settings.expended_rows ? 'font-size: 16px' : ''"> {{
              header.coin }}</span>
          </template>


          <template v-for="header in headers" v-slot:[`item.${header.value}`]="{ item, index }">
            <div :class="settings.expended_rows ? 'single-line-cell' : ''">
              <!-- original values values -->
              <span
                v-if="(header.type !== 'לינק' && !header.coin) || (item[header.value] === '' && !header.coin) || (tableExported === true && !header.coin)">
                <!-- this is regular field -->
                {{ item[header.value] }}</span>
              <!-- add coin icon to rows -->
              <span v-else-if="header.coin">{{ item[header.value] }} {{ header.coin }} </span>
              <!-- if type is  link change the values -->
              <div v-else>
                <div v-if="item[header.value]">
                  <v-btn elevation="2" v-if="!isNotAudio(item[header.value])" icon outlined
                    :color="$store.state.colorPrimary" @click="toggleAudio(item, item[header.value], index)">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" dark>
                          {{ item.playing ? 'mdi-stop' : 'mdi-play' }}
                        </v-icon>
                      </template>
                      <span>השמע קובץ</span>
                    </v-tooltip>
                  </v-btn>
                  <a :href="item[header.value]" :download="fileNameFromUrl(item[header.value])"
                    style="margin-inline-start: 10px;">
                    <v-btn elevation="2" icon outlined :color="$store.state.colorPrimary">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" dark>
                            mdi-download
                          </v-icon>
                        </template>
                        <span>הורד קובץ</span>
                      </v-tooltip>
                    </v-btn>
                  </a>
                </div>
              </div>
            </div>
          </template>

          <template v-slot:item.date_created="{ item, index }">
            <div :class="settings.expended_rows ? 'single-line-cell' : ''">
              <span style="font-size: small;">{{ formatDate(item.date_created) }}</span>
            </div>
          </template>

          <template v-slot:item.date_modified="{ item, index }">
            <div :class="settings.expended_rows ? 'single-line-cell' : ''">
              <span style="font-size: small;">{{ formatDate(item.date_modified) }}</span>
            </div>
          </template>

          <template v-slot:item.index="{ item, index }">
            <span>{{ index + 1 }}</span>
          </template>

          <template v-slot:no-data>
            <div class="ma-5">
              <span class="text-center" style="font-weight: bold; font-size: 25px; font-family: Times, serif;">לא נמצאו
                נתונים</span>
            </div>
            <v-btn :color="$store.state.colorPrimary" dark style="margin-bottom: 10px;" @click="refreshDetails()">
              רענן
            </v-btn>
          </template>

          <template v-slot:item.actions="{ item, index }" v-if="permission !== 'view'">
            <div v-if="selected.length <= 0" :class="settings.expended_rows ? 'single-line-cell' : ''">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" :color="$store.state.colorPrimary" medium class="mr-2 me-3"
                    @click="openEditDialog(item, index)">
                    mdi-pencil-outline
                  </v-icon>
                </template>
                <span>עריכה</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" :color="$store.state.colorPrimary" medium class="me-3"
                    @click="openDeleteDialog(item)">
                    mdi-delete-outline
                  </v-icon>
                </template>
                <span>מחיקה</span>
              </v-tooltip>
            </div>
          </template>

        </v-data-table>
      </v-col>
    </v-row>
    <AddEditDialog v-model="addEditDialog" v-if="addEditDialog" :formTitle="formTitle" :itemToEdit="editedItem"
      :fields="headersForEdit" :tableName="tableName" @itemAdded="newItemsaved" @itemEdited="editedItemsaved">
    </AddEditDialog>

    <DeleteItemDialog v-model="alertDialog" v-if="alertDialog" :formTitle="formTitle" :item="itemToDelete"
      @itemDeleted="itemDeleted" :tableName="tableName"> </DeleteItemDialog>

    <DeleteTableDialog v-model="deleteTableDialog" v-if="deleteTableDialog" @tableDeleted="tableDeleted"
      :tableName="tableName"> </DeleteTableDialog>

    <ImportDialog v-model="fileImported" :importedSuccess="importedSuccess" :importedFailed="importedFailed"
      @codesImportedSuccess="itemsImportedSuccessMessage" :tableName="tableName"></ImportDialog>

    <ExportDataToExcelDialog v-model="exportDialog" :tableData="tableToExport" @exportDataToExcel="downloadExcel"
      @exportExampleExcel="downloadExapmleExcel">
    </ExportDataToExcelDialog>

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    <SnackBarLoading v-model="loading" />

  </v-container>
</template>
  
<script>
import ApiServices from '@/services/api.service'
import TableToExcel, { isArray } from "@linways/table-to-excel";
import * as XLSX from 'xlsx/xlsx.mjs';
import AddEditDialog from '@/components/user/tableData/dialogs/AddEditDialog'
import DeleteItemDialog from '@/components/user/tableData/dialogs/DeleteItemDialog'
import DeleteTableDialog from '@/components/user/editTable/dialogs/DeleteTableDialog'
import SnackBar from '@/components/widgets/snackBar.vue'
import SnackBarLoading from '@/components/widgets/snackBarLoading.vue'
import ImportDialog from '@/components/user/tableData/dialogs/ImportFileDialog'
import ExportDataToExcelDialog from '@/components/user/tableData/dialogs/ExportDataToExcelDialog'
import Auth from '@/services/auth.service.js'
import _ from 'lodash'; // Import lodash or use another debounce utility
import Utils from '@/util/utils.js'

export default {
  components: {
    AddEditDialog,
    DeleteItemDialog,
    DeleteTableDialog,
    SnackBar,
    SnackBarLoading,
    ImportDialog,
    ExportDataToExcelDialog
  },
  props: {
    tableName: Object,
    value: { type: Boolean, default: false },
  },
  data: () => ({
    isResponsive: false, // Initially set to false
    fileImported: false,
    importedSuccess: [],
    importedFailed: [],
    progressSave: false,
    headers: [],
    headersForEdit: [],
    alertDialog: false,
    addEditDialog: false,
    deleteTableDialog: false,
    formTitle: "",
    items: [],
    loading: false,
    snackbar: false,
    snackbarColorBt: "green",
    snacbarText: "",
    editedItem: {},
    itemToDelete: {},
    userName: "",
    email: "",
    search: "",
    nonAudioExtensions: ['.pdf', '.txt', '.docx', '.xlsx'],
    tableExported: false,
    exportDialog: false,
    tableToExport: null,
    permission: "",
    itemsPerPage: 20,
    page: 1, // Current page
    totalItems: 0, // Total number of customers for pagination controls
    sortBy: "date_created",
    sortDesc: true, // default sort direction
    singleSelect: false,
    selected: [],
    settings: { multiple_selection: true, expended_rows: false }
  }),
  computed: {
    openMode: {
      get() {
        return this.value
      },
      set(v) {
        return this.$emit('input', v)
      }
    },
    visibleHeaders() {
      return this.headers.filter(header => header.show);
    },
    FieldsNotEmpty() {
      for (const field of this.headersForEdit) {
        if (field.value !== "date_created" && field.value !== "date_modified" && field.value !== "id") {
          return true;
        }
      }
      return false;
    }

  },
  methods: {

    async getData() {
      let auth = Auth.checkAuth();
      if (!auth) {
        this.$router.replace({ name: "login" })
      }

      try {
        this.loading = true;
        let token = localStorage.getItem("token");
        const tableJSON = JSON.stringify({ tableName: this.tableName.name, page: this.page, itemsPerPage: this.itemsPerPage, sortBy: this.sortBy, sortDesc: this.sortDesc });

        let api = process.env.VUE_APP_BASE_URL + "/user/get_table_data";
        const res = await fetch(api, ApiServices.requestOptions("POST", tableJSON, token));
        const jsonObject = await res.json();
        this.loading = false;
        if (res.status >= 400) {
          this.showSnackBar(" שגיאה בקבלת נתונים" + jsonObject.message, "red");
        } else if (res.status === 200) {
          if (!this.headers[0]) {
            this.setHeaders(jsonObject.jsonData.table_fields);
            this.$store.state.fields = jsonObject.jsonData.table_fields
          }
          this.items = jsonObject.jsonData.data;
          this.totalItems = jsonObject.totalItems;
          this.settings = jsonObject.jsonData.table_settings;
        }
      } catch (error) {
        this.loading = false;
        this.showSnackBar("Error get items list: " + error, "red");
      }
    },
    async getSearchData() {
      let auth = Auth.checkAuth();
      if (!auth) {
        this.$router.replace({ name: "login" })
      }
      try {
        this.loading = true;
        let token = localStorage.getItem("token");
        const tableJSON = JSON.stringify({ tableName: this.tableName.name, content: this.search, sortBy: this.sortBy, sortDesc: this.sortDesc });
        let api = process.env.VUE_APP_BASE_URL + "/user/get_table_search_data";
        const res = await fetch(api, ApiServices.requestOptions("POST", tableJSON, token));
        const jsonObject = await res.json();
        this.loading = false;
        if (res.status >= 400) {
          this.showSnackBar(" שגיאה בקבלת נתונים" + jsonObject.message, "red");
        } else if (res.status === 200) {
          this.items = jsonObject.data;
          this.totalItems = jsonObject.data.length;

        }
      } catch (error) {
        this.loading = false;
        this.showSnackBar("Error get items search list: " + error, "red");
      }
    },
    onPageChange(newPage) {
      this.page = newPage;
      this.refreshDetails();
    },
    onItemsPerPageChange(newItemsPerPage) {
      this.itemsPerPage = newItemsPerPage;
      // No need to call fetchCustomers() if you're using a watcher on itemsPerPage
    },
    setHeaders(headers1) {
      this.headers.push({ text: '#', value: "index", show: true, sortable: false })
      for (let i = 0; i < headers1.length; i++) {
        this.headers.push({ text: headers1[i].hebrew_name, value: headers1[i].name, unique: headers1[i].unique, show: headers1[i].show, type: headers1[i].type, allowNull: headers1[i].allowNull, list: headers1[i].list, linkedList: headers1[i].linkedList, coin: headers1[i].coin, sortable: true })
        this.headersForEdit.push({ text: headers1[i].hebrew_name, value: headers1[i].name, unique: headers1[i].unique, show: headers1[i].show, type: headers1[i].type, allowNull: headers1[i].allowNull, list: headers1[i].list, linkedList: headers1[i].linkedList, coin: headers1[i].coin, sortable: true })
      }
      this.headers.push({ text: 'פעולות', value: 'actions', show: true, sortable: false })
    },
    itemsImportedSuccessMessage(text, color) {
      this.showSnackBar(text, color)
      setTimeout(() => {
        this.$emit("refreshData");
      }, 500);

    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
    async downloadExcel() {
      try {
        this.loading = true;
        let token = localStorage.getItem("token");
        let api = `${process.env.VUE_APP_BASE_URL}/user/export_table_to_excel?tableName=${this.tableName.name}`;
        const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
        if (res.status >= 400) {
          this.showSnackBar("שגיאה בקבלת נתונים", "red");
        } else if (res.status === 200) {
          // Handle successful response
          const blob = await res.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = `${this.tableName.name}.xlsx`; // Suggested file name
          document.body.appendChild(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
        }
      } catch (error) {
        this.showSnackBar("Error get items search list: " + error, "red");
      } finally {
        this.loading = false;
      }
    },
    async downloadExapmleExcel() {
      this.loading = true
      const table = document.getElementById('table1');
      const clonedTable = table.cloneNode(true);

      // Replace the table headers with the English headers in the cloned table
      const tableHeaders = await clonedTable.getElementsByTagName('th');
      console.log(this.headers);
      console.log(tableHeaders);
      for (let i = 0; i < this.headers.length; i++) {
        for (let c = 0; c < tableHeaders.length; c++) {
          let header = tableHeaders[c].textContent;

          if (header.includes("₪") || header.includes("$") || header.includes("€")) {
            // Remove currency symbols and trim spaces
            header = header.replace(/[₪$€]/g, "").trim();
          }
          if (header === this.headers[i].text) {
            tableHeaders[c].textContent = this.headers[i].value;
          }
        }
      }
      //   // Remove the first header
      tableHeaders[0].parentNode.removeChild(tableHeaders[0]);
      //   // Remove the last header
      tableHeaders[tableHeaders.length - 1].parentNode.removeChild(tableHeaders[tableHeaders.length - 1]);

      // Remove all rows except the first row (headers) 
      const tableRows = clonedTable.getElementsByTagName('tr');
      while (tableRows.length > 1) {
        tableRows[1].parentNode.removeChild(tableRows[1]);
      }

      // Download the Excel file from the cloned table
      TableToExcel.convert(clonedTable, {
        name: 'exampleFile.xlsx',
        sheet: {
          name: 'Sheet 1',
        },
      });
    },
    refreshDetails() {
      if (this.search) {
        this.debouncedGetSearchData();
      } else {
        this.debouncedGetData();
      }
    },
    extendTable() {
      this.settings.expended_rows = !this.settings.expended_rows;
    },
    openExportDialog() {
      const table = document.getElementById('table1');
      const clonedTable = table.cloneNode(true);
      this.tableToExport = clonedTable;
      this.exportDialog = true
    },
    openEditDialog(item, index) {
      this.formTitle = "ערוך רשומה"
      this.editedItem = item
      this.editedItem.itemIndex = index;
      this.addEditDialog = true
    },

    openDeleteDialog(item) {
      this.itemToDelete = item
      if (Array.isArray(item)) {
        this.formTitle = "האם אתה בטוח שברצונך למחוק את הרשומות שנבחרו?"
      } else {
        this.formTitle = "האם אתה בטוח שברצונך למחוק את הרשומה?"
      }
      this.alertDialog = true
    },
    openCreateDIalog() {
      console.log(this.selected);
      this.formTitle = "הוסף רשומה חדשה"
      this.editedItem = {}
      this.addEditDialog = true
    },
    openEditTablePage() {
      this.$emit("openEditTable", this.tableName);
    },
    openTaskSchedulePage() {
      this.$emit("openTaskSchedule", this.tableName);
    },
    openCrmIntigrationPage() {
      this.$emit("openCrmIntigration", this.tableName);
    },
    tableDeleted(text, color) {
      this.showSnackBar(text, color)
      this.$emit("refreshTables");
    },
    newItemsaved(item, text, color) {

      this.showSnackBar(text, color)
      if (item === "") {
        return
      }
      if (this.items.length <= 0) {
        this.refreshDetails();
        return;
      }
      this.items.push(item);
      this.totalItems++;
    },
    editedItemsaved(item, text, color) {
      this.showSnackBar(text, color)
      if (item === "") {
        return
      }

      const index = this.items.findIndex(item1 => item1.id === item.id);
      let g = Object.assign(this.items[index], item);
    },

    itemDeleted(item, text, color) {
      if (!item) {
        this.showSnackBar(text, color)
        return
      }
      if (Array.isArray(item)) {
        item.forEach(singleItem => {
          const index = this.items.findIndex(item1 => item1.id === singleItem.id);
            this.items.splice(index, 1);
            this.totalItems--;
            this.selected = [];
        });
        text = "הרשומות נמחקו בהצלחה!";
      } else {
        // Use splice to remove the item at the specified index
        const index = this.items.findIndex(item1 => item1.id === item.id);
        this.items.splice(index, 1);
        this.totalItems--;
      }

      this.showSnackBar(text, color)
    },
    handleFileUpload(event) {

      const file = event.target.files[event.target.files.length - 1];

      if (file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          try {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(worksheet);

            outerLoop: for (let i = 0; i < jsonData.length; i++) {

              const row = jsonData[i];
              // יצירת אובייקט חדש והכנסת השורה מהטבלה
              let fullRow = {};
              this.headersForEdit.forEach((field) => {
                const value = row[field.value] !== undefined ? row[field.value] : '';
                this.$set(fullRow, field.value, value);
              });
              fullRow.id = ""


              // CHECK THAT ROW NOT EMPTY
              const allEmpty = Object.values(fullRow).every(value => value === "");
              if (allEmpty) {
                this.importedFailed.push(fullRow);
                console.log("all row empty");
                continue; // Skip to the next iteration if the format is invalid
              }
              // CHECK THAT ROW belongs to this table
              const keys = Object.keys(fullRow);
              const firstNameKey = keys[0];
              if (firstNameKey !== this.headersForEdit[0].value) {
                console.log("the rows title not fit to table headers");
                break;
              }

              // CHECK THAT row value not duplicate in fields set as unique and if the text fit to type field
              for (let i = 0; i < this.headersForEdit.length; i++) {
                if (this.headersForEdit[i].unique === true) {
                  const uniqueKey = this.headersForEdit[i].value;

                  if (keys.includes(uniqueKey)) {
                    const uniqueValue = row[uniqueKey];
                    for (let j = 0; j < this.items.length; j++) {
                      if (this.items[j][uniqueKey] === uniqueValue) {
                        console.log("found duplicate item in unique field");
                        console.log(row);
                        this.importedFailed.push(uniqueValue)
                        continue outerLoop; // Skip the main loop to the next iteration if a duplicate is found
                      }
                    }
                  }
                }

                let field = this.headersForEdit[i];
                const value = row[field.value];
                if (field.allowNull === false) {
                  if (!value) {
                    console.log(`cannot be empty in field '${field.value}': ${value}`);
                    console.log(row);
                    this.importedFailed.push(fullRow)
                    continue outerLoop; // Skip to the next iteration if the format is invalid
                  }
                }
                if (field.type === "מספר") {
                  if (value && !/^\d+(\.\d+)?$/.test(value)) {
                    console.log(`Invalid characters in field '${field.value}': ${value}, only numbers allow`);
                    console.log(row);
                    this.importedFailed.push(fullRow)
                    continue outerLoop; // Skip to the next iteration if the format is invalid
                  }
                } else if (field.type === "תאריך") {
                  // Check if the value is a valid date in the format "YYYY-MM-DD"
                  const isValidDate = /^\d{4}-\d{2}-\d{2}$/.test(value);
                  if (value && !isValidDate) {
                    console.log(`Invalid date format for field '${field.value}': ${value}`);
                    console.log(row);
                    this.importedFailed.push(fullRow)
                    continue outerLoop; // Skip to the next iteration if the format is invalid
                  }
                } else if (field.type === "תאריך ושעה") {
                  // Check if the value is a valid date and time in the format "YYYY-MM-DDTHH:mm"
                  const isValidDateTime = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/.test(value);
                  const isValidDateTime2 = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/.test(value);
                  if (value && !isValidDateTime && !isValidDateTime2) {
                    console.log(`Invalid date and time format for field '${field.value}': ${value} only 2023-07-19T16:23:37 or 2023-07-19 allowd`);
                    console.log(row);
                    this.importedFailed.push(fullRow);
                    continue outerLoop; // Skip to the next iteration if the format is invalid
                  }
                }

              }

              // remove spaces from strings
              for (const key in fullRow) {
                if (Object.hasOwnProperty.call(fullRow, key)) {
                  const value = fullRow[key];
                  // Check if the property is a string
                  if (typeof value === 'string') {
                    // Trim the string and update the object
                    fullRow[key] = value.trim();
                  }
                }
              }

              this.importedSuccess.push(fullRow)
              this.items.push(fullRow);
              this.totalItems++;
            }

            this.fileImported = true;

          } catch (error) {

            this.showSnackBar('Error parsing Excel file: ' + error, "red");
          }
        };

        reader.onerror = (e) => {
          this.showSnackBar('Error reading file: ' + e.target.error, "red");
        };

        reader.readAsArrayBuffer(file);
      }
    },
    toggleAudio(item, audioSrc) {

      if (item.playing) {
        this.stopAudio(item);
      } else {
        this.playAudio(item, audioSrc);
      }

    },
    playAudio(item, audioSrc) {

      item.audioElement = new Audio(audioSrc);
      item.audioElement.play();
      item.playing = true;
      item.audioElement.addEventListener('ended', () => {
        this.stopAudio(item);
      });
      item.audioElement.addEventListener('error', (event) => {
        this.stopAudio(item);
        this.showSnackBar('שגיאה: קובץ שמע לא תקין', "red");
      });
    },
    stopAudio(item) {
      if (item.audioElement) {
        item.audioElement.pause();
        item.audioElement.currentTime = 0;
        item.playing = false;
      }
    },
    isNotAudio(link) {
      if (link) {
        const extension = link.slice(link.lastIndexOf('.')).toLowerCase();
        return this.nonAudioExtensions.includes(extension);
      }
      else return true
    },
    fileNameFromUrl(src) {
      // Splits the URL by '/' and then takes the last element
      return src.split('/').pop();
    },
    onSortChange() {
      // Called whenever sortBy or sortDesc changes
      this.refreshDetails();
    },
    handleEnterKey() {
      if (this.search) {
        this.debouncedGetSearchData()
      } else {
        this.debouncedGetData()
      }
    },
    formatDate(date) {
      if (!date) {
        return "";
      }
      return Utils.getPrettyDate(date);
    }
    // downloadFile(url) {
    //   const fileURL = url; // Set the path to your file
    //   const fileName = fileURL.extension; // Set the desired filename and extension
    //   saveAs(fileURL, fileName);
    // },
  },
  watch: {
    tableName() {
      this.refreshDetails();
    },
    itemsPerPage(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.refreshDetails();
      }
    },
    selected(newVal, oldVal) {
      console.log(newVal, oldVal);
    },
  },
  mounted() {
    let auth = Auth.checkAuth();
    if (!auth) {
      this.$router.replace({ name: "login" })
    }
    this.getData();
    this.permission = Auth.getUserPermission();

    // Create a debounced version of getData to avoid multiple rapid calls
    this.debouncedGetData = _.debounce(this.getData, 300);
    // Create a debounced version of getData to avoid multiple rapid calls
    this.debouncedGetSearchData = _.debounce(this.getSearchData, 300);
  },
}
</script>
<style scoped>
@media (min-width: 766px) {

  .topTable {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
}

.divider {
  border-top: 0.5px solid #cfcccc;
  /* Set the border style, width, and color */
  margin-top: 10px;
  /* Optional: Add margin to adjust spacing */
  margin-bottom: 20px !important;
  /* Optional: Add margin to adjust spacing */
}

.user-info {
  display: flex;
  flex-direction: column;
}

.user-info span {
  margin-right: 2px;
}

.logout-button-container {
  display: flex;
  align-items: center;
}


/* change second row to grey in table in all website*/
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .04);
}


.v-icon.theme--light {
  color: your-color;
}

::-webkit-scrollbar {
  width: 10px;
  /* Adjust the width of the scrollbar */
}

/* Customizes the scrollbar track (space to scroll) */
::-webkit-scrollbar-track {
  background-color: #FFFFFF;
  /* Sets the track color to white */
}

/* Customizes the scrollbar thumb (the draggable element) */
::-webkit-scrollbar-thumb {
  background-color: rgb(219, 218, 218);
  /* Sets the thumb color to blue */
  border-radius: 3px;
  /* Optional: Adds rounded corners to the thumb */
}
</style>
  


<template>
    <v-dialog v-model="openMode" max-width="400px">
        <v-card class="gradient-grey-button-background">
            <v-card-title class="text-h5" :style="{ color: $store.state.colorSecondary }">{{ "בחר את מקור הרשימה"
            }}</v-card-title>
            <v-card-text>
                <div style="margin-top: 20px;">
                    <v-col>
                        <v-row style=" margin-top: 10px;">
                            <v-col cols="11">
                                <v-select :items="tablesSourceList" v-model="fieldEditedLinkedList.linkedList.tableSource"
                                    item-value="name" item-text="hebrew_name" label="בחר טבלת מקור" outlined dense
                                    hide-details :rules="[v => !!v || 'שדה חובה']" required></v-select>
                            </v-col>
                        </v-row>
                        <v-row style=" margin-top: 10px;">
                            <v-col cols="11">
                                <v-select :items="fieldsSourceList" v-model="fieldEditedLinkedList.linkedList.fieldSource"
                                    item-value="name" item-text="hebrew_name" label="בחר שדה מקור" outlined dense
                                    hide-details :rules="[v => !!v || 'שדה חובה']" required></v-select>
                            </v-col>
                        </v-row>
                    </v-col>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :color="$store.state.colorPrimary" text @click="closeList()">ביטול</v-btn>
                <v-btn :color="$store.state.colorPrimary" text @click="actionConfirmed()">אישור</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </v-dialog>
</template>
  
<script>
import SnackBar from '@/components/widgets/snackBar.vue'
import ApiServices from '@/services/api.service'

export default {
    props: {
        field: Object,
        fieldIndex: Number,
        value: { type: Boolean, default: false },
    },
    components: {
        SnackBar,
    },
    data: () => ({
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        fieldEditedLinkedList: {},
        tablesSourceList: [],
        fieldsSourceList: []
    }),

    methods: {
        async getTableSourceFields() {
            try {
                this.fieldsSourceList = [];
                let token = localStorage.getItem("token");
                const tableJSON = JSON.stringify({ tableName: this.fieldEditedLinkedList.linkedList.tableSource});

                let api = process.env.VUE_APP_BASE_URL + "/user/get_table_properties";
                const res = await fetch(api, ApiServices.requestOptions("POST", tableJSON, token));
                const jsonObject = await res.json();
                if (res.status >= 400) {
                    this.showSnackBar(" שגיאה בקבלת נתונים" + jsonObject.message, "red");
                } else if (res.status === 200) {
                    this.fieldsSourceList = jsonObject.fieldsData;
                }
            } catch (error) {
                this.showSnackBar("Error get items list: " + error, "red");
            }
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
        actionConfirmed() {
            this.$emit("listLinkedUpdated", this.fieldEditedLinkedList, this.fieldIndex);
            this.openMode = false;
        },
        closeList() {
            this.$emit("listLinkedUpdated");
            this.openMode = false;
        },

    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
    },
    watch: {
        'fieldEditedLinkedList.linkedList.tableSource'(newValue, oldValue) {
            console.log(newValue);
            this.getTableSourceFields();
        }
    },
    created() {
        //deep clone
        // this.fields = this.$store.state.fields;
        this.fieldEditedLinkedList = JSON.parse(JSON.stringify(this.field));
        this.tablesSourceList = this.$store.state.tables;
    },
}
</script>
<style></style>
    